import React, { useState, useEffect, useMemo } from 'react';
import useBrowserInfo from '../hooks/use-browser-info';
import logger from '../utils/logger';
import InstallPromptCss from '../css/InstallPWA.css';
import { loadIntroDataFromCache } from '../utils/local-storage';
import '../css/Home.css';
import InfoIcon from '../icons/info-icon';
import { useInstallPWA } from '../providers/install-pwa-provider';
import { AlertCircle, Info } from 'lucide-react';

const checkIntroSeen = async () => {
  const introData = await loadIntroDataFromCache();
  return introData?.seen;
};

let isIntroSeen = false;

checkIntroSeen().then((value) => {
  isIntroSeen = value;
});

const PromptDisplayInterval = 7 * 24 * 60 * 60 * 1000;
const InstallPromptKey = 'INSTALL_PROMPT_DISMISSED';

const isInstallPromptDismissed = () => {
  const dismissedTimestamp = localStorage.getItem(InstallPromptKey);
  if (!dismissedTimestamp) {
    return false;
  }
  const now = Date.now();
  return now - dismissedTimestamp < PromptDisplayInterval;
};

export function dismissInstallPrompt() {
  localStorage.setItem(InstallPromptKey, Date.now());
}

const ManualInstructions = () => (
  <div className="mt-2 text-sm text-gray-600">
    <p>To add manually:</p>
    <ol className="list-decimal ml-5 mt-1">
      <li>Click the menu icon (⋮ or ⋯) in your browser</li>
      <li>Select "Install app" or "Add to Home screen"</li>
      <li>Follow the on-screen instructions</li>
    </ol>
  </div>
);

const InstallPrompt = ({ message, buttonLabel, onButtonClick, showButton, onClose }) => (
  <div className="install-prompt">
    <div className="install-prompt-content">
      <span className="install-prompt-text">{message}</span>
      {showButton && (
        <button className="install-prompt-button" onClick={onButtonClick}>
          {buttonLabel}
        </button>
      )}
    </div>
    <button className="install-close-button" onClick={onClose}>
      ✖
    </button>
  </div>
);

const InstallPWABannerContent = ({ isIOS, onInstallClick, onClose }) => {
  const [showManualInstructions, setShowManualInstructions] = useState(false);

  const toggleManualInstructions = () => {
    setShowManualInstructions(!showManualInstructions);
  };

  if (isIOS) {
    return (
      <InstallPrompt
        message={
          <div>
            <span>
              <>
                To save this site as a bookmark on your device for an improved experience,
                tap the share icon
                <span className="share-icon">⬆️</span> next to your browser's address bar,
                then scroll down and select "Add to Home Screen".
              </>
            </span>
          </div>
        }
        showButton={false}
        onClose={onClose}
      />
    );
  }

  return (
    <InstallPrompt
      message={
        <div>
          <div>
            <span>Install this app on your device for an improved experience&nbsp;</span>
            {showManualInstructions && <ManualInstructions />}
            {!showManualInstructions && (
              <>
                (Having trouble?{' '}
                <span onClick={toggleManualInstructions} className="underlined">
                  View manual instructions
                </span>
                )
              </>
            )}
          </div>
        </div>
      }
      buttonLabel="Install"
      onButtonClick={onInstallClick}
      showButton={true}
      onClose={onClose}
    />
  );
};

const InstallPWAIntroContent = ({ isIOS, onInstallClick, onClose, closeLabel }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [showManualInstructions, setShowManualInstructions] = useState(false);

  const handleInfoClick = () => setShowInfo((prev) => !prev);
  const toggleManualInstructions = () => setShowManualInstructions((prev) => !prev);

  return (
    <>
      <h2 className="intro-title">Install Our App</h2>
      <p className="intro-paragraph">
        Enjoy a faster, smoother experience with our app right on your home screen.
        Installing the app ensures quicker access and a more seamless interface.
      </p>

      {isIOS ? (
        <p className="intro-paragraph">
          To add this app to your home screen, tap the share icon
          <span className="share-icon">⬆️</span> next to your browser's address bar, then
          scroll down and select "Add to Home Screen."
        </p>
      ) : (
        <>
          <p className="intro-paragraph">
            Click the "Install" button below to add this app to your device.&nbsp;
            {!showManualInstructions && (
              <>
                (Having trouble?{' '}
                <span onClick={toggleManualInstructions} className="underlined">
                  View manual instructions
                </span>
                )
              </>
            )}
            {showManualInstructions && <ManualInstructions />}
          </p>
        </>
      )}

      <p className="intro-paragraph">
        It's simple and safe—this is just a shortcut to this web page, with no extra
        permissions or access to your data.
        <button onClick={handleInfoClick} className="info-icon">
          <Info size={12} />
        </button>
      </p>

      {showInfo && (
        <div className="info-content">
          <p>
            Why not use app stores? App stores often moderate content, impose rules that
            may limit functionality, and charge fees that could force us to introduce paid
            features. By keeping our app outside of app stores, we can keep it free for
            you and maintain full control over its features.
          </p>
        </div>
      )}

      {!isIOS && (
        <button className="install-prompt-button" onClick={onInstallClick}>
          Install
        </button>
      )}
      <button className="install-prompt-button" onClick={onClose}>
        {closeLabel}
      </button>
    </>
  );
};

const InstallPWA = ({ children }) => {
  const { deferredPrompt, setDeferredPrompt } = useInstallPWA();
  const { isIOS, isStandalone } = useBrowserInfo();

  useEffect(() => {
    const beforeInstallPromptHandler = (e) => {
      console.log('beforeInstallPromptHandler called!');
      e.preventDefault();
      setDeferredPrompt(e);
    };
    console.log('Adding beforeinstallprompt event listener');

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    };
  }, []);

  useEffect(() => {
    const appInstalledHandler = () => {
      console.log('PWA was installed');
    };

    window.addEventListener('appinstalled', appInstalledHandler);

    return () => {
      window.removeEventListener('appinstalled', appInstalledHandler);
    };
  }, []);

  const handleInstallClick = async () => {
    console.log('deferredPrompt', deferredPrompt);
    if (!deferredPrompt) return;

    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    if (outcome === 'accepted') {
      console.log('User accepted the install prompt');
    } else {
      console.log('User dismissed the install prompt');
    }
    setDeferredPrompt(null);
  };

  if (isStandalone) {
    return null;
  }

  return children({ isIOS, onInstallClick: handleInstallClick });
};

export const InstallPWABanner = () => {
  const [showBanner, setShowBanner] = useState(
    !isInstallPromptDismissed() && isIntroSeen,
  );

  const handleClose = () => {
    dismissInstallPrompt();
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <InstallPWA>
      {({ isIOS, onInstallClick }) => (
        <InstallPWABannerContent
          isIOS={isIOS}
          onInstallClick={onInstallClick}
          onClose={handleClose}
        />
      )}
    </InstallPWA>
  );
};

export const InstallPWAIntroMessage = ({ onClose, closeLabel = 'Skip' }) => (
  <InstallPWA>
    {({ isIOS, onInstallClick }) => (
      <InstallPWAIntroContent
        isIOS={isIOS}
        onInstallClick={onInstallClick}
        onClose={onClose}
        closeLabel={closeLabel}
      />
    )}
  </InstallPWA>
);

export default InstallPWA;
