import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { SunriseSun } from '../icons/sun-svg-icon';
import AppHeader from '../components/app_header';
import { SunTypes } from '../components/sun_path';
import { getCircadianEventBySunType } from '../components/circadian_event';
import { useBackground } from '../hooks/use-background-animation';
import { ModalTypes, useModal } from '../providers/modal-provider';
import { useSolarClock } from '../providers/solar-clock-provider';
import { getSunType } from '../domain/sun-type';
import useBrowserInfo from '../hooks/use-browser-info';
import SunIcon, { isSunIcon, isMoonIcon } from '../icons/sun_icon';
import { InfoPanelState, useInfoPanel } from '../components/info-panel';
import UvNavigation from '../components/uv-nav';
import {
  calculateSafeForecastedSunExposureTime,
  calculateSafeSunExposureTime,
  calculateUVStatus,
  UVRiskLevels,
} from '../utils/uv-content';
import { useUvContext } from '../providers/uv-provider';
import {
  convertMinutesToHoursAndMinutes,
  Days,
  formatDayTimeAndZone,
  isNow,
} from '../utils/time';
import '../css/UV.css';
import { sleep } from '../utils/async';
import { getFormattedTime, isToday } from '../utils/time';
import { UvProvider } from '../providers/uv-provider';
import { HeaderTime } from '../components/floating-time';
import { DateTime } from 'luxon';
import MoonPhase from '../icons/moon-svg';
import SiteNav from '../components/site-nav';
import LocationPage from './location-page';
import { usePage } from '../providers/page-provider';

// Helper function to find the closest time based on a comparison condition (either earlier or later)
const findClosestTime = (times, targetDateTime, comparator) => {
  // Convert the object to an array of entries ([key, value]) and filter based on the comparator
  const filteredEntries = Object.entries(times).filter(([key, time]) =>
    comparator(time, targetDateTime),
  );

  if (filteredEntries.length === 0) {
    return null; // Return null if no valid times are found
  }

  // Find the entry with the minimum time difference
  const closestEntry = filteredEntries.reduce((closest, current) => {
    const [closestKey, closestTime] = closest;
    const [currentKey, currentTime] = current;
    return Math.abs(currentTime.diff(targetDateTime).milliseconds) <
      Math.abs(closestTime.diff(targetDateTime).milliseconds)
      ? current
      : closest;
  });

  return closestEntry; // Return the [key, value] of the closest time
};

// Find the closest time that is later than the target
const findClosestLaterTime = (times, targetDateTime) => {
  return findClosestTime(times, targetDateTime, (time, target) => time > target);
};

// Find the closest time that is earlier than the target
const findClosestEarlierTime = (times, targetDateTime) => {
  return findClosestTime(times, targetDateTime, (time, target) => time < target);
};

function UltraVioletPage() {
  const {
    timezone,
    isLocationLoading,
    activeEvent,
    activateEvent,
    clearEvent,
    today,
    sunTimes,
    displayDate,
    isNight,
    moonPhase,
    coordinates,
    elevation,
    isDateToday,
  } = useSolarClock();

  const { skinType, uvIndex, maxUv, maxUvTime, isUvLoaded, uvForecast } = useUvContext();
  const uvDay = isDateToday(displayDate) ? 'Today' : 'Tomorrow';
  const uvIndexLabel = isNow(displayDate) ? 'Current' : activeEvent;

  const sunType = useMemo(() => {
    return getSunType(sunTimes, displayDate);
  }, [sunTimes, displayDate]);

  const isDarkTheme = useMemo(() => {
    return isNight(displayDate);
  }, [displayDate]);

  const contentClass = isDarkTheme
    ? 'uv-page-content dark-mode-text-color'
    : 'uv-page-content';
  const { isMobileLayout } = useBrowserInfo();
  const { debug } = usePage();
  const { openModal } = useModal();
  const openExposureGuideModal = useCallback(
    () => openModal(ModalTypes.ExposureGuide),
    [],
  );
  const openSkinTypeModal = useCallback(() => openModal(ModalTypes.SkinType), []);

  useBackground(sunType);

  const closestLaterSunTime = useMemo(() => {
    return findClosestLaterTime(sunTimes, displayDate);
  }, [sunTimes, displayDate]);
  const closestEarlierSunTime = useMemo(() => {
    return findClosestEarlierTime(sunTimes, displayDate);
  }, [sunTimes, displayDate]);

  const uvStatus = useMemo(() => {
    return calculateUVStatus(
      sunTimes,
      closestEarlierSunTime,
      closestLaterSunTime,
      displayDate,
      uvIndex,
      maxUv,
      maxUvTime,
      timezone,
      uvDay,
    );
  }, [
    sunTimes,
    closestEarlierSunTime,
    closestLaterSunTime,
    displayDate,
    uvIndex,
    maxUv,
    maxUvTime,
    timezone,
    uvDay,
    isUvLoaded,
  ]);

  const handleShowExposureGuide = () => {
    openExposureGuideModal();
  };

  const displayUvIndex = useMemo(() => {
    return isUvLoaded && uvIndex ? uvIndex : '-';
  }, [isUvLoaded, uvIndex]);

  const displaySafeExposureTime = useMemo(() => {
    if (!isUvLoaded || uvIndex == null || !coordinates?.latitude || !displayDate) {
      console.log('No safe exposure time to display');
      return '-';
    }

    if (!skinType) {
      return '';
    }
    if (uvIndex === 0) {
      return 'No limit';
    }

    const safeExposureTime = calculateSafeSunExposureTime(
      uvIndex,
      elevation,
      coordinates.latitude,
      displayDate,
      skinType,
    );

    // Compare the safeExposureTime with the difference between `date` and `sunTimes.uvaSet`
    const difference = new Date(sunTimes.uvaSet - new Date(displayDate)) / (1000 * 60); // difference in minutes
    if (difference <= safeExposureTime) {
      return 'No limit';
    }

    return convertMinutesToHoursAndMinutes(safeExposureTime);
  }, [uvIndex, elevation, coordinates.latitude, skinType, displayDate, sunTimes.uvaSet]);

  const displayForecastSafeExposureTime = useMemo(() => {
    if (!isUvLoaded || uvIndex == null || !coordinates?.latitude || !displayDate) {
      return '-';
    }

    if (!skinType) {
      return '';
    }
    if (uvIndex === 0) {
      return 'No limit';
    }

    const forecastSafeExposureTime = calculateSafeForecastedSunExposureTime(
      uvIndex,
      Days.Today(timezone),
      uvForecast,
      elevation,
      coordinates.latitude,
      displayDate,
      skinType,
    );

    // Compare the safeExposureTime with the difference between `date` and `sunTimes.uvaSet`
    const difference = new Date(sunTimes.uvaSet - new Date(displayDate)) / (1000 * 60); // difference in minutes
    if (difference <= forecastSafeExposureTime) {
      return 'No limit';
    }

    return convertMinutesToHoursAndMinutes(forecastSafeExposureTime);
  }, [uvIndex, elevation, coordinates.latitude, skinType, displayDate, sunTimes.uvaSet]);

  const sunRadius = isMobileLayout ? 60 : 75;
  const moonRadius = isMobileLayout ? 75 : 125;

  return (
    <div className="App">
      <AppHeader
        darkTheme={!isSunIcon(sunType)}
        sunType={sunType}
        isLoaded={isUvLoaded}
      />
      <SiteNav darkTheme={isDarkTheme} isLoaded={!isUvLoaded} sunType={sunType} />
      <UvNavigation
        darkTheme={isDarkTheme}
        isLocationLoading={isLocationLoading}
        sunType={sunType}
      />
      <div>
        <div className={contentClass}>
          <div className="uv-index">
            <div className="uv-index-item">
              <div className="underlined">{uvIndexLabel} UV</div>
              <div>{uvIndex}</div>
            </div>
            <div className="uv-index-item">
              <div className="underlined">Max UV</div>
              <div>
                {maxUv} at {getFormattedTime(maxUvTime)}
              </div>
            </div>
          </div>
          <div className="exposure-guide">
            <div id="uv-status-anchor" className="uv-status">
              {uvStatus}
              {debug && (
                <div>
                  <strong>
                    isUvLoaded: {isUvLoaded?.toString()}
                    <br />
                    uvIndex: {uvIndex}
                    <br />
                    elevation: {elevation}
                    <br />
                    coordinates.latitude: {coordinates?.latitude}
                    <br />
                    displayDate: {formatDayTimeAndZone(displayDate)}
                  </strong>
                </div>
              )}
            </div>
            <div id="uv-exposure-anchor">
              <div>
                <span
                  id="safe-time-anchor"
                  onClick={openExposureGuideModal}
                  className="link"
                >
                  Safe Exposure Time:
                </span>{' '}
                {displayForecastSafeExposureTime}
                {!skinType && (
                  <span
                    className={`skinTypesPrompt ${skinType ? '' : 'link'}`}
                    onClick={openSkinTypeModal}
                  >
                    Select your skin type
                  </span>
                )}
              </div>
              {debug && (
                <div>
                  <span onClick={openExposureGuideModal} className="link">
                    Safe Exposure Time
                  </span>{' '}
                  at Current UV: {displaySafeExposureTime}
                  {!skinType && (
                    <span
                      className={`skinTypesPrompt ${skinType ? '' : 'link'}`}
                      onClick={openSkinTypeModal}
                    >
                      Select your skin type
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="uv-page-sun">
            {isSunIcon(sunType) && (
              <SunIcon radius={sunRadius} sunType={sunType} isMobile={isMobileLayout} />
            )}
            {isMoonIcon(sunType) && <MoonPhase radius={moonRadius} phase={moonPhase} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function UltraVioletPageWrapper({ onLoad, isLoaded }) {
  return (
    <LocationPage>
      <UltraVioletPage onLoad={onLoad} isLoaded={isLoaded} />;
    </LocationPage>
  );
}
