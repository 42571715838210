// src/components/AddSubscriber.jsx
import React, { useState } from 'react';
import { addSubscriber, MailingListGroups } from '../clients/mailer-lite';
import '../css/SignUp.css';
import ThreeDotsBounce from '../icons/three-dots-bounce';
import { useAuth } from '../providers/auth-provider';
import { useModal } from '../providers/modal-provider';

const AddSubscriber = ({ isCodeRequired, onSuccess, additionalGroups = [] }) => {
  const [subscriberEmail, setSubscriberEmail] = useState('');
  const [referrer, setReferrer] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { authenticate } = useAuth();
  const { closeModal } = useModal();

  function getSuccessMessage(alreadySubscribed = false) {
    if (alreadySubscribed) {
      return isCodeRequired
        ? "You're already registered! You now have access."
        : "You're already subscribed to our list!";
    }
    return isCodeRequired ? 'You now have access!' : "You've been added to the list!";
  }

  function getFailureMessage() {
    return isCodeRequired
      ? 'There was an error getting access for the preview release, please try again.'
      : 'There has been an error adding you to the list, please try again.';
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!subscriberEmail) {
      setMessage('Please enter an email address.');
      return;
    }

    setLoading(true);
    try {
      // Always add to SIL and General Content groups
      const groups = [
        MailingListGroups.SIL_BETA.id,
        MailingListGroups.SIL.id,
        MailingListGroups.GENERAL.id,
        ...additionalGroups,
      ];

      const result = await addSubscriber(subscriberEmail, referrer, groups);

      if (result.success) {
        setMessage(getSuccessMessage(result.alreadySubscribed));
        if (isCodeRequired) {
          authenticate(subscriberEmail);
          closeModal();
        }
        if (onSuccess) {
          onSuccess(result.data);
        }
      } else {
        setMessage(getFailureMessage());
      }
    } catch (error) {
      setMessage(`Error: ${error.response?.data?.message || 'Unknown error occurred'}`);
    } finally {
      setLoading(false);
    }
  };

  const buttonStyles = isCodeRequired
    ? 'gradient-button button-medium button-shadow'
    : 'gradient-button button-medium';

  return (
    <div>
      <h3>{isCodeRequired ? 'PREVIEW RELEASE' : 'SIGN UP FOR MY NEWSLETTER'}</h3>
      <div>
        {!isCodeRequired &&
          'Sign up with your email address to receive occasional updates, including insights\n' +
            'on circadian and quantum biology, news about the site, and product\n' +
            'recommendations.'}
        {isCodeRequired &&
          "Welcome to the preview release of Sunlight is Life. Enter your name, email address, and let us know how you found us to get access. We'll use your email only to gather feedback about the app and keep you updated about changes. Enjoy!"}
      </div>
      <div className="signUpForm">
        <form onSubmit={handleSubmit}>
          <div className="emailInput">
            <input
              type="email"
              value={subscriberEmail}
              onChange={(e) => setSubscriberEmail(e.target.value)}
              placeholder="Email Address"
              required
              className="form-input form-input-medium"
            />
          </div>
          <div className="emailInput">
            <input
              type="text"
              value={referrer}
              onChange={(e) => setReferrer(e.target.value)}
              placeholder="How did you hear about us?"
              className="form-input form-input-medium"
            />
          </div>
          <div className="emailInput">
            <button type="submit" className={buttonStyles} disabled={loading}>
              {loading ? <ThreeDotsBounce /> : 'Sign Up'}
            </button>
          </div>
        </form>
      </div>
      {message && <p>{message}</p>}
    </div>
  );
};

export default AddSubscriber;
