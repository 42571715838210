import React, { useState } from 'react';

import '../css/Splash.css';
import { clearMessages, messages } from '../utils/logger';
import { formatDayTimeAndZone } from '../utils/time';
import { useSolarClock } from '../providers/solar-clock-provider';
import AppHeader from '../components/app_header';
import { SunTypes } from '../components/sun_path';

function DebugLogPage() {
  const [activeTab, setActiveTab] = useState('Debug Info');

  const {
    coordinates,
    location,
    timezone,
    currentTime,
    displayDate,
    today,
    tomorrow,
    sunTimes,
    moonPhase,
    activeEvent,
  } = useSolarClock();

  return (
    <div className="App">
      <AppHeader darkTheme={true} sunType={SunTypes.Twilight} isLoaded={true} />
      <div className="content-container dark-mode-text-color">
        <div className="debug-tabs">
          <button onClick={() => setActiveTab('Debug Info')}>Debug Info</button>
          <button onClick={() => setActiveTab('Sun Info')}>Sun Data</button>
        </div>
        {activeTab === 'Debug Info' && (
          <div className="debug-info-container">
            <div className="debug-messages dark-mode-text-color">
              {messages.length > 0 ? (
                messages.map((msg, index) => (
                  <div key={index}>
                    <span>[{msg.type}]</span> <span>{msg.message}</span>
                  </div>
                ))
              ) : (
                <div>No debug messages available.</div>
              )}
            </div>
            <button onClick={clearMessages}>Clear Messages</button>
          </div>
        )}
        {activeTab === 'Sun Info' && (
          <div className="debug-container dark-mode-text-color">
            <div>
              <span>Location:</span>
              <span>{JSON.stringify(location, undefined, 2)}</span>
            </div>
            <div>
              <span>Timezone:</span>
              <span>{timezone}</span>
            </div>
            <div>
              <span>Coordinates:</span>
              <span>{JSON.stringify(coordinates, undefined, 2)}</span>
            </div>
            <div>
              <span>Current Time:</span>
              <span>{formatDayTimeAndZone(currentTime)}</span>
            </div>
            <div>
              <span>Display Date:</span>
              <span>{formatDayTimeAndZone(displayDate)}</span>
            </div>
            <div>
              <span>Today:</span>
              <span>{formatDayTimeAndZone(today)}</span>
            </div>
            <div>
              <span>Tomorrow:</span>
              <span>{formatDayTimeAndZone(tomorrow)}</span>
            </div>
            <div>
              <span>Active Event:</span>
              <span>{activeEvent}</span>
            </div>
            <div>
              <span>Sun Times:</span>
              <span>{JSON.stringify(sunTimes, undefined, 2)}</span>
            </div>
            <div>
              <span>MoonPhase:</span>
              <span>{JSON.stringify(moonPhase, undefined, 2)}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DebugLogPage;
