import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import infoPanelBriefContentMap from '../data/info-panel-content-brief';
import infoPanelExpandedContentMap from '../data/info-panel-content';
import '../css/Tooltip.css';
import useBrowserInfo from '../hooks/use-browser-info';
import SlidingPanel from './SlidingPanel';
import { useSolarClock } from '../providers/solar-clock-provider';
import { Pages, usePage } from '../providers/page-provider';

const InfoPanelContext = createContext();

export const InfoPanelState = {
  Closed: 'closed',
  Preview: 'preview',
  Full: 'full',
};

const DefaultContent = {
  content: 'No content available',
  position: 'nadir',
  state: InfoPanelState.Closed,
};

const BriefContent = ({
  key,
  name,
  isDetailedInfo,
  isExpandable,
  handleToggle,
  onClose,
}) => {
  const { goToPage } = usePage();

  const briefContent =
    infoPanelBriefContentMap[key] || infoPanelBriefContentMap[name] || DefaultContent;

  const { definition, action, explanation, recommendedProducts } = briefContent;

  function onClickProduct(params) {
    onClose();
    goToPage(Pages.Products, params);
  }

  return (
    <ContentTemplate
      name={name}
      isDetailedInfo={isDetailedInfo}
      isExpandable={isExpandable}
      handleToggle={handleToggle}
    >
      <strong>What is it?</strong>
      <div>{definition}</div>
      <strong>What to do?</strong>
      <div>{action}</div>
      <strong>Why do it?</strong>
      <div>{explanation}</div>
      {!isDetailedInfo && isExpandable && (
        <div className="more-less-info" onClick={handleToggle}>
          Learn more &gt;&gt;
        </div>
      )}
      {recommendedProducts?.length > 0 && (
        <div>
          <strong>Recommended Products</strong>
          <div>
            {recommendedProducts.map((product, index) => {
              return (
                <>
                  <span
                    key={product.id}
                    className="recommended-product"
                    onClick={() => onClickProduct({ targetProduct: product.id })}
                  >
                    {product.name}
                  </span>
                  {index < recommendedProducts.length - 1 && ', '}
                </>
              );
            })}
          </div>
        </div>
      )}
    </ContentTemplate>
  );
};

function ExpandedContent({ key, name, isDetailedInfo, isExpandable, handleToggle }) {
  const expandedContent =
    infoPanelExpandedContentMap[key]?.content ||
    infoPanelExpandedContentMap[name]?.content ||
    '';

  return (
    <ContentTemplate
      name={name}
      isDetailedInfo={isDetailedInfo}
      isExpandable={isExpandable}
      handleToggle={handleToggle}
    >
      <div dangerouslySetInnerHTML={{ __html: expandedContent }} />
    </ContentTemplate>
  );
}

//() => handleToggleContent(name)
const ContentTemplate = ({
  name,
  isDetailedInfo,
  isExpandable,
  handleToggle,
  children,
}) => {
  return (
    <div>
      <h3>{name}</h3>
      {children}
      {isDetailedInfo && isExpandable && (
        <div className="more-less-info" onClick={handleToggle}>
          &lt;&lt; Go back
        </div>
      )}
    </div>
  );
};
export const InfoPanelProvider = ({ children }) => {
  const { isMobileLayout } = useBrowserInfo();
  const [infoPanel, setInfoPanel] = useState(DefaultContent);
  const [isDetailedInfo, setDetailedInfo] = useState(false);
  const { goToPage } = usePage();

  const {
    sunTimes: { uvbRise },
  } = useSolarClock();

  useEffect(() => {
    if (infoPanel.name !== DefaultContent.name) {
      showInfoPanel(infoPanel.name);
    }
  }, [isDetailedInfo]);

  const showInfoPanelPreview = (name) => {
    const state = InfoPanelState.Preview;
    const infoPanelState = {
      name,
      content: '',
      position: undefined,
      state,
      visible: state === InfoPanelState.Full,
    };
    setInfoPanel(infoPanelState);
    setTimeout(() => showInfoPanel(name), 550);
  };

  const handleToggleContent = (name) => {
    setDetailedInfo((prevVal) => !prevVal);
  };

  const showInfoPanel = (name, type = 'Expandable') => {
    const isExpandable = type === 'Expandable';
    let key = name;

    if (uvbRise === undefined) {
      key = `${name} No UVB`;
    }

    let contentComponent;
    if (isDetailedInfo) {
      contentComponent = (
        <ExpandedContent
          key={key}
          name={name}
          isDetailedInfo={isDetailedInfo}
          isExpandable={isExpandable}
          handleToggle={handleToggleContent}
          onClose={hideInfoPanel}
        />
      );
    } else {
      contentComponent = (
        <BriefContent
          key={key}
          name={name}
          isDetailedInfo={isDetailedInfo}
          isExpandable={isExpandable}
          handleToggle={handleToggleContent}
          onClose={hideInfoPanel}
        />
      );
    }

    const state = InfoPanelState.Full;
    const infoPanelState = {
      name,
      content: contentComponent,
      state,
      visible: state === InfoPanelState.Full,
    };
    setInfoPanel(infoPanelState);
  };

  const hideInfoPanel = () => {
    const state = InfoPanelState.Closed;
    setInfoPanel(DefaultContent);
    setDetailedInfo(false);
  };

  const infoPanelIsOpen = useMemo(
    () => infoPanel?.state === InfoPanelState.Full,
    [infoPanel?.state],
  );
  return (
    <InfoPanelContext.Provider
      value={{
        infoPanel,
        showInfoPanelPreview,
        showInfoPanel,
        hideInfoPanel,
        infoPanelIsOpen,
      }}
    >
      {children}
      {
        <SlidingPanel
          name={infoPanel.name}
          content={infoPanel.content}
          state={infoPanel.state}
        />
      }
    </InfoPanelContext.Provider>
  );
};

const Tooltip = ({ content, position }) => {
  return <div className={`tooltip ${position}`}>{content}</div>;
};

export const useInfoPanel = () => useContext(InfoPanelContext);
