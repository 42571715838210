import React, { useCallback, useEffect } from 'react';
import { useSolarClock } from '../providers/solar-clock-provider';
import { useAuth } from '../providers/auth-provider';
import { ModalTypes, useModal } from '../providers/modal-provider';
import { CircadianEventType } from './circadian_event';
import '../css/Nav.css';
import { useInfoPanel } from './info-panel';
import useFeature, { FeatureFlags } from '../hooks/use-feature';
import { useTutorial } from '../providers/tutorial-provider';

const Navigation = ({ darkTheme, isLocationLoading }) => {
  const { user } = useAuth();
  const isFeatureActive = useFeature();
  const { openModal } = useModal();
  const { hideInfoPanel } = useInfoPanel();
  const {
    isDateToday,
    isDateTomorrow,
    today,
    tomorrow,
    location,
    activateEvent,
    clearEvent,
  } = useSolarClock();
  const { nextStep, stepIndex } = useTutorial();

  const openLocationModal = useCallback(() => {
    if (isFeatureActive(FeatureFlags.AllowLocationSearch)) {
      openModal(ModalTypes.LocationSearch);
      nextStep();
    }
  }, []);

  useEffect(() => {
    if (stepIndex === 8) {
      onClickTomorrow();
    }
  }, [stepIndex]);

  if (isLocationLoading) {
    return (
      <div className="nav-wrapper-loading">
        <div className="nav-item-loading">&nbsp;</div>
      </div>
    );
  }
  const styleSuffix = darkTheme ? '-dark' : '-light';
  const todayClass = isDateToday()
    ? `nav-item-selected${styleSuffix}`
    : `nav-item${styleSuffix}`;
  const tomorrowClass = isDateTomorrow()
    ? `nav-item-selected${styleSuffix}`
    : `nav-item${styleSuffix}`;
  const navWrapperClass = `nav-wrapper${styleSuffix}`;
  const navItemClass = `nav-item${styleSuffix}`;
  const displayLocation = isLocationLoading ? '--, --' : location;

  const onClickTomorrow = () => {
    activateEvent(CircadianEventType.Sunrise, tomorrow);
    hideInfoPanel();
  };

  const onClickToday = () => {
    clearEvent(today);
    hideInfoPanel();
    nextStep();
  };

  return (
    <div className={navWrapperClass}>
      <>
        <div
          id="location-display-anchor"
          className={navItemClass}
          onClick={openLocationModal}
        >
          {displayLocation}
        </div>
        <div id="today-anchor" className={todayClass} onClick={onClickToday}>
          Today
        </div>
        <div id="tomorrow-anchor" className={tomorrowClass} onClick={onClickTomorrow}>
          Tomorrow
        </div>
      </>
    </div>
  );
};

export default Navigation;
