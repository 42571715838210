import React, { useState, useEffect } from 'react';
import '../css/LearnMore.css';
import AppHeader from '../components/app_header';
import { SunTypes } from '../components/sun_path';
import { useBackground } from '../hooks/use-background-animation';
import CopyButton from '../icons/copy-svg';
import SettingItem from '../components/settings';
import versionJson from '../data/version.json';
import { usePage } from '../providers/page-provider';
import { useTutorial } from '../providers/tutorial-provider';
import useFeature, { FeatureFlags } from '../hooks/use-feature';
import { formatDay, formatDayTimeAndZone, formatShortDayAndTime } from '../utils/time';
import useNotificationSystem from '../hooks/use-notification-system';
import { useSolarClock } from '../providers/solar-clock-provider';
import { useSunTimes } from '../hooks/use-sun-times';
import useBrowserInfo from '../hooks/use-browser-info';
import { dismissInstallPrompt, InstallPWAIntroMessage } from '../components/install-pwa';
import logger from '../utils/logger';

const { DateTime } = require('luxon');

const DefaultNotificationOffset = 15;

const InstallContainer = ({ onClose }) => {
  return (
    <div className="settings-install-prompt">
      <InstallPWAIntroMessage onClose={onClose} closeLabel="Close" />
    </div>
  );
};

const SettingsContent = () => {
  const { toggleDebug, debug } = usePage();
  const { isTutorialActive, activateTutorial, dismissTutorial } = useTutorial();
  const isFeatureActive = useFeature();
  const [notificationState, setNotificationState] = useState('default');
  const [locationState, setLocationState] = useState('prompt');
  const [compassState, setCompassState] = useState('prompt');
  const [isInstallVisible, setIsInstallVisible] = useState(false);
  const { sunTimes, coordinates, timezone, tomorrow } = useSolarClock();
  const sunTimesTomorrow = useSunTimes(coordinates, tomorrow, timezone);
  const { notificationSettings, updateEventSettings, triggerImmediateNotification } =
    useNotificationSystem(sunTimes, sunTimesTomorrow);
  const { isStandalone } = useBrowserInfo();

  useEffect(() => {
    const checkPermissions = async () => {
      // Check notification permission
      if ('Notification' in window && Notification) {
        setNotificationState(Notification.permission);
      }

      // Check geolocation permission
      try {
        const result = await navigator.permissions.query({ name: 'geolocation' });
        setLocationState(result.state);

        result.addEventListener('change', () => {
          setLocationState(result.state);
        });
      } catch (error) {
        console.error('Error checking location permission:', error);
      }

      // Check Device Orientation permission
      if (typeof DeviceOrientationEvent !== 'undefined') {
        if (typeof DeviceOrientationEvent.requestPermission === 'function') {
          // iOS device - needs explicit permission
          setCompassState('prompt');
        } else {
          // Other devices - typically granted by default
          setCompassState('granted');
        }
      } else {
        // Device Orientation not supported
        setCompassState('unsupported');
      }
    };

    void checkPermissions().catch((e) =>
      logger.log('Error checking permissions', e.message),
    );
  }, []);

  const getPermissionButton = (type, state) => {
    // Special handling for unsupported compass
    if (type === 'Compass' && state === 'unsupported') {
      return {
        hasButton: true,
        buttonLabel: 'Not Supported',
        onButtonClick: () => {},
        disabled: true,
      };
    }

    const getButtonProps = () => {
      if (state === 'granted') {
        return {
          hasButton: true,
          buttonLabel: `Enabled`,
          onButtonClick: () => {},
          disabled: true,
        };
      }

      if (state === 'denied') {
        return {
          hasButton: true,
          buttonLabel: `Blocked`,
          onButtonClick: () => {
            alert(
              `To enable ${type.toLowerCase()}, click the lock icon in your browser's address bar, find the ${type.toLowerCase()} permission, and change it to "Ask" or "Allow"`,
            );
          },
        };
      }

      return {
        hasButton: true,
        buttonLabel: `Request`,
        onButtonClick: async () => {
          if (type === 'Location') {
            navigator.geolocation.getCurrentPosition(
              () => {
                setLocationState('granted');
              },
              () => {
                setLocationState('denied');
              },
            );
          } else if (type === 'Notifications') {
            const result = await Notification.requestPermission();
            setNotificationState(result);
          } else if (type === 'Compass') {
            try {
              if (typeof DeviceOrientationEvent.requestPermission === 'function') {
                const result = await DeviceOrientationEvent.requestPermission();
                setCompassState(result);
              } else {
                // For non-iOS devices, just mark as granted since permission isn't needed
                setCompassState('granted');
              }
            } catch (error) {
              console.error('Error requesting compass permission:', error);
              setCompassState('denied');
            }
          }
        },
      };
    };

    return getButtonProps();
  };

  const timeSelectOptions = [
    { value: 5, label: '5 minutes' },
    { value: 10, label: '10 minutes' },
    { value: DefaultNotificationOffset, label: '15 minutes' },
    { value: 30, label: '30 minutes' },
  ];

  const eventNotificationsSettings = isFeatureActive(FeatureFlags.IsNotificationsEnabled)
    ? {
        label: 'Event Notifications',
        hasExpand: true,
        subItems: [
          {
            label: 'Morning Events',
            hasExpand: true,
            subItems: [
              {
                label: 'Dawn',
                hasToggle: true,
                isToggled: notificationSettings.dawn.enabled,
                onToggle: (value) => updateEventSettings('dawn', 'enabled', value),
                hasExpand: true,
                expandWithToggle: true, // New prop to auto-expand when toggled
                subItems: [
                  {
                    label: '', // Empty because the TimeSelect component will show "Notify me ... before event"
                    hasTimeSelect: true,
                    timeSelectValue: notificationSettings.dawn.timeOffset,
                    onTimeSelectChange: (value) =>
                      updateEventSettings('dawn', 'timeOffset', value),
                    timeSelectOptions: timeSelectOptions,
                    parentToggle: true,
                  },
                ],
              },
              {
                label: 'Sunrise',
                hasToggle: true,
                isToggled: notificationSettings.sunrise.enabled,
                onToggle: (value) => updateEventSettings('sunrise', 'enabled', value),
                hasExpand: true,
                expandWithToggle: true,
                subItems: [
                  {
                    label: '',
                    hasTimeSelect: true,
                    timeSelectValue: notificationSettings.sunrise.timeOffset,
                    onTimeSelectChange: (value) =>
                      updateEventSettings('sunrise', 'timeOffset', value),
                    timeSelectOptions: timeSelectOptions,
                    parentToggle: true,
                  },
                ],
              },
              {
                label: 'UVA Rise',
                hasToggle: true,
                isToggled: notificationSettings.uvaRise.enabled,
                onToggle: (value) => updateEventSettings('uvaRise', 'enabled', value),
                hasExpand: true,
                expandWithToggle: true,
                subItems: [
                  {
                    label: '',
                    hasTimeSelect: true,
                    timeSelectValue: notificationSettings.uvaRise.timeOffset,
                    onTimeSelectChange: (value) =>
                      updateEventSettings('uvaRise', 'timeOffset', value),
                    timeSelectOptions: timeSelectOptions,
                    parentToggle: true,
                  },
                ],
              },
              {
                label: 'UVB Rise',
                hasToggle: true,
                isToggled: notificationSettings.uvbRise.enabled,
                onToggle: (value) => updateEventSettings('uvbRise', 'enabled', value),
                hasExpand: true,
                expandWithToggle: true,
                subItems: [
                  {
                    label: '',
                    hasTimeSelect: true,
                    timeSelectValue: notificationSettings.uvbRise.timeOffset,
                    onTimeSelectChange: (value) =>
                      updateEventSettings('uvbRise', 'timeOffset', value),
                    timeSelectOptions: timeSelectOptions,
                    parentToggle: true,
                  },
                ],
              },
            ],
          },
          {
            label: 'Evening Events',
            hasExpand: true,
            subItems: [
              {
                label: 'UVB Set',
                hasToggle: true,
                isToggled: notificationSettings.uvbSet.enabled,
                onToggle: (value) => updateEventSettings('uvbSet', 'enabled', value),
                hasExpand: true,
                expandWithToggle: true,
                subItems: [
                  {
                    label: '',
                    hasTimeSelect: true,
                    timeSelectValue: notificationSettings.uvbSet.timeOffset,
                    onTimeSelectChange: (value) =>
                      updateEventSettings('uvbSet', 'timeOffset', value),
                    timeSelectOptions: timeSelectOptions,
                    parentToggle: true,
                  },
                ],
              },
              {
                label: 'UVA Set',
                hasToggle: true,
                isToggled: notificationSettings.uvaSet.enabled,
                onToggle: (value) => updateEventSettings('uvaSet', 'enabled', value),
                hasExpand: true,
                expandWithToggle: true,
                subItems: [
                  {
                    label: '',
                    hasTimeSelect: true,
                    timeSelectValue: notificationSettings.uvaSet.timeOffset,
                    onTimeSelectChange: (value) =>
                      updateEventSettings('uvaSet', 'timeOffset', value),
                    timeSelectOptions: timeSelectOptions,
                    parentToggle: true,
                  },
                ],
              },
              {
                label: 'Sunset',
                hasToggle: true,
                isToggled: notificationSettings.sunset.enabled,
                onToggle: (value) => updateEventSettings('sunset', 'enabled', value),
                hasExpand: true,
                expandWithToggle: true,
                subItems: [
                  {
                    label: '',
                    hasTimeSelect: true,
                    timeSelectValue: notificationSettings.sunset.timeOffset,
                    onTimeSelectChange: (value) =>
                      updateEventSettings('sunset', 'timeOffset', value),
                    timeSelectOptions: timeSelectOptions,
                    parentToggle: true,
                  },
                ],
              },
              {
                label: 'Dusk',
                hasToggle: true,
                isToggled: notificationSettings.dusk.enabled,
                onToggle: (value) => updateEventSettings('dusk', 'enabled', value),
                hasExpand: true,
                expandWithToggle: true,
                subItems: [
                  {
                    label: '',
                    hasTimeSelect: true,
                    timeSelectValue: notificationSettings.dusk.timeOffset,
                    onTimeSelectChange: (value) =>
                      updateEventSettings('dusk', 'timeOffset', value),
                    timeSelectOptions: timeSelectOptions,
                    parentToggle: true,
                  },
                ],
              },
              {
                label: 'Nightfall',
                hasToggle: true,
                isToggled: notificationSettings.nightfall.enabled,
                onToggle: (value) => updateEventSettings('nightfall', 'enabled', value),
                hasExpand: true,
                expandWithToggle: true,
                subItems: [
                  {
                    label: '',
                    hasTimeSelect: true,
                    timeSelectValue: notificationSettings.nightfall.timeOffset,
                    onTimeSelectChange: (value) =>
                      updateEventSettings('nightfall', 'timeOffset', value),
                    timeSelectOptions: timeSelectOptions,
                    parentToggle: true,
                  },
                ],
              },
            ],
          },
        ],
      }
    : undefined;

  const settings = [
    {
      label: 'Permissions',
      hasExpand: true,
      subItems: [
        {
          label: 'Location',
          ...getPermissionButton('Location', locationState),
        },
        {
          label: 'Compass',
          ...getPermissionButton('Compass', compassState),
        },
        {
          label: 'Notifications',
          ...getPermissionButton('Notifications', notificationState),
        },
      ],
    },
    {
      label: 'Install App',
      hasButton: true,
      buttonLabel: isStandalone ? 'Installed' : 'Install Now',
      onButtonClick: () => setIsInstallVisible(true),
      disabled: isStandalone,
    },
    {
      label: 'Tutorial On',
      hasToggle: true,
      isToggled: isTutorialActive,
      onToggle: () => (isTutorialActive ? dismissTutorial() : activateTutorial()),
    },
    {
      label: `Version ${versionJson.version}`,
      hasExpand: true,
      subItems: [
        {
          label: `Released: ${formatShortDayAndTime(DateTime.fromISO(versionJson.date))}`,
        },
      ],
    },
  ];

  if (isFeatureActive(FeatureFlags.DebugIsActive)) {
    settings.splice(2, 0, {
      label: 'Debug On',
      hasToggle: true,
      isToggled: debug,
      onToggle: () => toggleDebug(),
    });
  }

  if (isFeatureActive(FeatureFlags.IsNotificationsEnabled)) {
    settings.splice(2, 0, {
      label: 'Test Notification',
      hasButton: true,
      buttonLabel: 'Notify Now',
      onButtonClick: () => triggerImmediateNotification('This is a test notification'),
    });
  }
  if (eventNotificationsSettings) {
    settings.splice(1, 0, eventNotificationsSettings);
  }

  return (
    <>
      {isInstallVisible && (
        <InstallContainer onClose={() => setIsInstallVisible(false)} />
      )}
      <div className="w-full max-w-2xl border rounded-lg">
        {settings.map((setting, index) => (
          <SettingItem key={index} {...setting} />
        ))}
      </div>
    </>
  );
};

const SettingsPage = ({ isLoaded }) => {
  useBackground(SunTypes.Twilight);

  return (
    <div className="App">
      <AppHeader darkTheme={true} sunType={SunTypes.Twilight} isLoaded={isLoaded} />
      <div className="learn-more">
        <div className="settings-container">
          <SettingsContent />
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
