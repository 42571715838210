import { UvDataTypes, UvUpdateInterval } from '../clients/uv-utils';

export const loadDataFromCache = async (tag) => {
  // Check if data is already in localStorage
  const cachedData = localStorage.getItem(tag);
  if (cachedData) {
    return JSON.parse(cachedData);
  }
};

export const saveDataToCache = async (tag, data) => {
  // Store the fetched data in localStorage
  localStorage.setItem(tag, JSON.stringify(data));
};

export const deleteDataFromCache = async (tag) => {
  // Remove the specified data from localStorage
  localStorage.removeItem(tag);
};

const LocationDataTag = 'LOCATION_DATA';
const SkinTypeDataTag = 'SKIN_TYPE_DATA';
const AdjustmentFactorsTag = 'ADJUSTMENT_FACTORS';
const UserDataTag = 'USER_ID_DATA';
const UvDataTag = 'UV_DATA';
const IntroSeenTag = 'INTRO_SEEN_DATA';
const TutorialSeenTag = 'TUTORIAL_SEEN_DATA';

export async function loadLocationDataFromCache() {
  return await loadDataFromCache(LocationDataTag);
}

export const saveLocationDataToCache = async (data) => {
  await saveDataToCache(LocationDataTag, data);
};

export const deleteLocationDataFromCache = async () => {
  await deleteDataFromCache(LocationDataTag);
};

export async function loadSkinTypeDataFromCache() {
  return await loadDataFromCache(SkinTypeDataTag);
}

export const saveSkinTypeDataToCache = async (data) => {
  await saveDataToCache(SkinTypeDataTag, data);
};

export async function loadAdjustmentFactorsFromCache() {
  return await loadDataFromCache(AdjustmentFactorsTag);
}

export const saveAdjustmentFactorsToCache = async (data) => {
  await saveDataToCache(AdjustmentFactorsTag, data);
};

export async function loadUserDataFromCache() {
  return await loadDataFromCache(UserDataTag);
}

export const saveUserDataToCache = async (data) => {
  await saveDataToCache(UserDataTag, data);
};

export async function loadUvDataFromCache(latitude, longitude, displayDate, type) {
  const cacheKeyDate = formatCacheDate(displayDate, type);
  const cachedData = await loadDataFromCache(UvDataTag);
  const cacheKey = `${latitude}_${longitude}_${cacheKeyDate}_${type}`;

  if (cachedData && cachedData[cacheKey]) {
    const { data, timestamp } = cachedData[cacheKey];
    const now = new Date().getTime();

    if (type === UvDataTypes.CURRENT && now - timestamp < UvUpdateInterval) {
      return data;
    } else if (type === UvDataTypes.FORECAST) {
      const cachedDate = new Date(timestamp).toDateString();
      const currentDate = new Date().toDateString();

      if (cachedDate === currentDate) {
        return data;
      }
    }
  }
  return null;
}

export const saveUvDataToCache = async (latitude, longitude, data, displayDate, type) => {
  const cacheKeyDate = formatCacheDate(displayDate, type);
  let cachedData = await loadDataFromCache(UvDataTag);
  if (!cachedData) {
    cachedData = {};
  }
  const cacheKey = `${latitude}_${longitude}_${cacheKeyDate}_${type}`;
  cachedData[cacheKey] = {
    data,
    timestamp: new Date().getTime(),
  };
  await saveDataToCache(UvDataTag, cachedData);
};

function formatCacheDate(date, type) {
  const formattedDate = new Date(date);
  if (type === UvDataTypes.CURRENT) {
    const minutes = formattedDate.getMinutes();
    formattedDate.setMinutes(minutes < 30 ? 0 : 30, 0, 0);
  } else if (type === UvDataTypes.FORECAST) {
    formattedDate.setHours(0, 0, 0, 0);
  }
  return formattedDate.toDateString();
}

export async function loadIntroDataFromCache() {
  return await loadDataFromCache(IntroSeenTag);
}

export const saveIntroDataToCache = async (data) => {
  await saveDataToCache(IntroSeenTag, data);
};

export async function loadTutorialDataFromCache() {
  return await loadDataFromCache(TutorialSeenTag);
}

export const saveTutorialDataToCache = async (data) => {
  await saveDataToCache(TutorialSeenTag, data);
};
