let isLoggingEnabled = false; //process.env.ENABLE_LOGGING === 'true';

const messages = [];

const addMessage = (type, ...args) => {
  //  console.log('Added message', type, ...args)
  messages.push({ type, message: args.join(' ') });
};

const clearMessages = () => {
  messages.length = 0;
};

const toggleLogging = () => {
  isLoggingEnabled = !isLoggingEnabled;
};

const logger = {
  log: (...args) => {
    if (isLoggingEnabled) {
      console.log(...args);
      addMessage('log', ...args);
    }
  },
  info: (...args) => {
    if (isLoggingEnabled) {
      console.info(...args);
      addMessage('info', ...args);
    }
  },
  warn: (...args) => {
    //    console.log('Logger Warn', args)
    if (isLoggingEnabled) {
      console.warn(...args);
      addMessage('warn', ...args);
    }
  },
  error: (...args) => {
    if (isLoggingEnabled) {
      console.error(...args);
      addMessage('error', ...args);
    }
  },
};

export { messages, clearMessages, toggleLogging };
export default logger;
