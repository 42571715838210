import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePage } from '../providers/page-provider';
import { SunriseSun } from '../icons/sun-svg-icon';
import { HeaderTop } from '../components/app_header';
import { SunTypes } from '../components/sun_path';
import { useBackground } from '../hooks/use-background-animation';
import { ModalTypes, useModal } from '../providers/modal-provider';
import { useAuth } from '../providers/auth-provider';

import '../css/Home.css';
import { sleep } from '../utils/async';
import useFeature, { FeatureFlags } from '../hooks/use-feature';
import { loadIntroDataFromCache, saveIntroDataToCache } from '../utils/local-storage';
import AddSubscriber from '../components/add-subscriber';
import { useTutorial } from '../providers/tutorial-provider';
import useBrowserInfo from '../hooks/use-browser-info';
import InstallPWA, {
  dismissInstallPrompt,
  InstallPWAIntroMessage,
} from '../components/install-pwa';

const markIntroAsSeen = () => {
  saveIntroDataToCache({ seen: true });
};

const isIntroSeen = async () => {
  const introData = await loadIntroDataFromCache();
  return introData?.seen;
};

function InstallContent({ onClose, onInstall }) {
  return (
    <>
      <InstallPWAIntroMessage onClose={onClose} closeLabel="Skip" />
    </>
  );
}

function AppIntroContent({ onClick }) {
  return (
    <>
      <h2 className="intro-title">Sunlight Sustains All Life.</h2>
      <p className="intro-paragraph">
        Throughout history, cultures have celebrated the sun's vital role in sustaining
        all life on Earth. Today, we are taught that it is dangerous. At{' '}
        <strong>Sunlight is Life</strong>, we celebrate the sun as a powerful source of
        vitality. Connecting with nature and welcoming the sun's energy opens new paths to
        thriving. The effects of sunlight are complex and profound—even small changes can
        spark positive transformations, and we're here to show you how.
      </p>
      <button className="gradient-button button-small button-shadow" onClick={onClick}>
        Next
      </button>
    </>
  );
}

function AppInstructionsContent({ onClick }) {
  return (
    <>
      <h2 className="intro-subtitle">How to Use This App</h2>
      <h3>This app has three components:</h3>

      <div className="component-section">
        <div className="component-title">Schedule:</div>
        <div className="component-paragraph">
          Highlights key solar events throughout the day and optimal sun exposure
          strategies.
        </div>
      </div>

      <div className="component-section">
        <div className="component-title">UV:</div>
        <div className="component-paragraph">
          Shows the current UV index and gives you personalized safe sun exposure times.
        </div>
      </div>

      <div className="component-section">
        <div className="component-title">Compass:</div>
        <div className="component-paragraph">
          Locates the sunrise position so you always know where to look, wherever you are.
        </div>
      </div>

      <button className="gradient-button button-small button-shadow" onClick={onClick}>
        Next
      </button>
    </>
  );
}

function SignInContent({ onClose }) {
  const { isAuthenticated, user } = useAuth();
  return (
    <>
      <div>
        <AddSubscriber
          isCodeRequired={!isAuthenticated || !user.id}
          onSuccess={onClose}
        />
      </div>
    </>
  );
}

const IntroMessage = ({ onClose }) => {
  const { isStandalone } = useBrowserInfo();
  const [visible, setVisible] = useState(false);
  const [installPromptDismissed, setInstallPromptDismissed] = useState(isStandalone);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setTimeout(() => setVisible(true), 100);
  }, []);

  const goToNextPage = () => {
    console.log('Going to next page');
    setPage((prevPage) => (prevPage + 1) % 3);
  };

  const onSkipInstall = () => {
    setInstallPromptDismissed(true);
    dismissInstallPrompt();
  };

  const onInstall = () => {
    setInstallPromptDismissed(true);
  };

  if (!installPromptDismissed) {
    return (
      <div className={`intro-container ${visible ? 'fade-in' : ''}`}>
        <InstallContent onInstall={goToNextPage} onClose={onSkipInstall} />
      </div>
    );
  }

  console.log('page', page);
  return (
    <div className={`intro-container ${visible ? 'fade-in' : ''}`}>
      {page === 0 && <AppIntroContent onClick={goToNextPage} />}
      {page === 1 && <AppInstructionsContent onClick={goToNextPage} />}
      {page === 2 && <SignInContent onClose={onClose} />}
    </div>
  );
};

const WendellBerryQuote = 'The light of nature cannot be seen when artificially lit.';
const WhitmanQuote =
  'Keep your face always toward the sunshine and shadows will fall behind you.';
const DailyQuote = WendellBerryQuote;
const QuoteAuthor = DailyQuote === WendellBerryQuote ? 'Wendell Berry' : 'Walt Whitman';

function HomePage({ onLoad, isLoaded }) {
  const { goToPage } = usePage();
  const { user } = useAuth();
  const isFeatureActive = useFeature();
  const [quote, setQuote] = useState(isLoaded ? DailyQuote : undefined);
  const [author, setAuthor] = useState(isLoaded ? QuoteAuthor : undefined);
  const [showIntroMessage, setShowIntroMessage] = useState(undefined);
  const { startTutorial, nextStep } = useTutorial();

  useEffect(() => {
    console.log('Mounted!');
    if (isLoaded && !showIntroMessage) {
      setTimeout(() => startTutorial(), 1250);
    }
    return () => {
      console.log('Unmounted!');
    };
  }, []);

  useEffect(() => {
    isIntroSeen().then((isSeen) => {
      if (isSeen) {
        setShowIntroMessage(false);
      } else {
        setShowIntroMessage(true);
      }
    });
  }, []);

  const sunriseAnimationClass = useMemo(() => {
    if (showIntroMessage === true) {
      return 'sunrise-intro-animation';
    }
    return 'sunrise-animation';
  }, [showIntroMessage]);

  useBackground(SunTypes.Twilight);

  const onLoadPageLoad = useCallback(() => {
    console.log('On Home page load');
    onLoad();
    setQuote(DailyQuote);
    setAuthor('Walt Whitman');
    isIntroSeen().then((isSeen) => {
      if (isSeen) {
        setTimeout(() => startTutorial(), 1250);
      }
    });
  }, [showIntroMessage, startTutorial]);

  useEffect(() => {
    if (!isLoaded) {
      sleep(500).then(onLoadPageLoad);
    }
  }, []);

  const { openModal } = useModal();
  const openSignUpModal = useCallback(() => openModal(ModalTypes.SignUp), []);

  function handleCloseIntro() {
    setShowIntroMessage(false);
    markIntroAsSeen();
    setTimeout(() => startTutorial(), 1250);
  }

  const handleClickSchedule = () => {
    nextStep();
    goToPage('/clock');
  };

  return (
    <div className="App">
      <div className="header dark-mode-text-color">
        <HeaderTop
          darkTheme={true}
          sunType={SunTypes.Twilight}
          isLoaded={!showIntroMessage}
        />
      </div>
      <div className="content-container">
        {showIntroMessage && <IntroMessage onClose={handleCloseIntro} />}
        {!showIntroMessage && quote && (
          <>
            <div className="buttons-container">
              <button
                id="schedule-button-anchor"
                className="gradient-button button-large"
                onClick={handleClickSchedule}
              >
                Schedule
              </button>
              <button
                className="gradient-button button-large"
                onClick={() => goToPage('/uv')}
              >
                UV
              </button>
              {isFeatureActive(FeatureFlags.CompassPageIsActive) && (
                <button
                  className="gradient-button button-large"
                  onClick={() => goToPage('/compass')}
                >
                  Compass
                </button>
              )}
              {!isFeatureActive(FeatureFlags.CompassPageIsActive) && (
                <button
                  className="gradient-button button-large"
                  onClick={() => goToPage('/learn-more')}
                >
                  Learn More
                </button>
              )}
            </div>
            <div id="quote-anchor" className="quote-container">
              “{quote}”{' '}
              {author && (
                <>
                  <br />―{author}
                </>
              )}
            </div>
          </>
        )}
        <div className={`sunrise-container ${sunriseAnimationClass}`}>
          <SunriseSun radius={75} />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
