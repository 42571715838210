import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import '../css/SettingItem.css';

// Custom Toggle Switch component
const Toggle = ({ checked, onChange }) => {
  return (
    <button
      type="button"
      role="switch"
      aria-checked={checked}
      onClick={(e) => {
        e.stopPropagation();
        onChange(!checked);
      }}
      className={`toggle-switch ${checked ? 'toggle-switch--checked' : 'toggle-switch--unchecked'}`}
    >
      <span
        className={`toggle-switch__handle ${
          checked ? 'toggle-switch__handle--checked' : 'toggle-switch__handle--unchecked'
        }`}
      />
    </button>
  );
};

// Action Button component
const ActionButton = ({ onClick, label }) => {
  return (
    <button
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      className="settings-item__action-button"
    >
      {label}
    </button>
  );
};

const TimeSelect = ({ onChange, value, options }) => {
  return (
    <select
      value={value}
      onChange={(e) => {
        e.stopPropagation();
        onChange(e.target.value);
      }}
      className="settings-item__time-select"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

const SettingItem = ({
  label,
  onClick,
  hasRadio,
  isRadioSelected,
  hasToggle,
  isToggled,
  onToggle,
  hasExpand,
  isExpanded,
  hasButton,
  buttonLabel,
  onButtonClick,
  hasTimeSelect,
  timeSelectValue,
  onTimeSelectChange,
  timeSelectOptions,
  subItems,
  depth = 0,
  isVisible = true,
  expandWithToggle = false,
}) => {
  const [expanded, setExpanded] = useState(isExpanded || false);

  // Update expanded state when toggle changes
  useEffect(() => {
    if (expandWithToggle && hasToggle && isToggled) {
      setExpanded(true);
    }
  }, [expandWithToggle, hasToggle, isToggled]);

  // If not visible, return null
  if (!isVisible) return null;

  const handleClick = (e) => {
    e.stopPropagation();
    if (onClick) {
      onClick(e);
    }
  };

  const handleExpand = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <div>
      <div
        onClick={handleClick}
        className={`settings-item ${depth > 0 && !hasTimeSelect ? 'depth-1' : ''}`}
      >
        <div className="settings-item__left">
          {hasRadio && (
            <div className="settings-item__radio">
              <div
                className={`settings-item__radio-circle ${
                  isRadioSelected ? 'settings-item__radio-circle--selected' : ''
                }`}
              />
            </div>
          )}
          <span
            className={
              hasTimeSelect ? 'settings-item__notification-label' : 'settings-item__label'
            }
          >
            {label}
            {hasTimeSelect && (
              <>
                {' Notify me '}
                <TimeSelect
                  value={timeSelectValue}
                  onChange={onTimeSelectChange}
                  options={timeSelectOptions}
                />
                {' before event'}
              </>
            )}
          </span>
        </div>

        <div className="settings-item__right">
          {hasToggle && <Toggle checked={isToggled} onChange={onToggle} />}
          {hasButton && <ActionButton onClick={onButtonClick} label={buttonLabel} />}
          {hasExpand && !expandWithToggle && (
            <button onClick={handleExpand} className="settings-item__expand-button">
              {expanded ? <ChevronDown /> : <ChevronRight />}
            </button>
          )}
        </div>
      </div>

      {hasExpand && expanded && subItems && (
        <div className="settings-item__sub-items">
          {subItems.map((item, index) => (
            <SettingItem
              key={index}
              {...item}
              depth={depth + 1}
              isVisible={item.parentToggle ? isToggled : true} // Only show if parent toggle is on
            />
          ))}
        </div>
      )}
    </div>
  );
};
export default SettingItem;
