import React, { useEffect, useRef } from 'react';
import '../css/LearnMore.css';
import AppHeader from '../components/app_header';
import { SunTypes } from '../components/sun_path';
import { useBackground } from '../hooks/use-background-animation';
import CopyButton from '../icons/copy-svg'; // Import the CopyButton component

const emailData = [
  { label: 'For feedback', address: 'feedback@sunlightislife.com' },
  { label: 'For support or feature requests', address: 'support@sunlightislife.com' },
  { label: 'To say hi', address: 'hello@sunlightislife.com' },
];

const FeedbackContent = () => (
  <>
    <h2>Thank You for Using Sunlight is Life</h2>
    <p>
      My name is Peter, and I created this app as a labor of love while exploring my own
      path toward a more vibrant life through the power of sunlight and living in harmony
      with nature. What began as a personal tool grew into something I shared with
      friends, and eventually, I decided to make it available to everyone. Along with this
      app, I run <a href="https://livingenergywellness.com">Living Energy Wellness</a>,
      where I help others on their own health journey. I'm so grateful to have you as part
      of this growing community.
    </p>
    <p>
      This is a one-person operation, so while I strive to make everything as seamless as
      possible, there may occasionally be small issues here and there. I truly appreciate
      your understanding and patience as I continue to improve and refine the app.
    </p>
    <p>
      Have feedback, a feature request, or just want to say hi? I’d love to hear from you!
      Your thoughts and experiences help me improve, so please don’t hesitate to reach
      out.
    </p>
    <h3>Contact Me</h3>
    <ul>
      {emailData.map(({ label, address }) => (
        <li key={address}>
          {label}: <a href={`mailto:${address}`}>{address}</a>{' '}
          <CopyButton address={address} />
        </li>
      ))}
    </ul>
    <p>I look forward to hearing from you!</p>
  </>
);

const FeedbackPage = ({ isLoaded }) => {
  const contentRef = useRef(null);
  useBackground(SunTypes.Twilight);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, []);

  return (
    <div className="App">
      <AppHeader darkTheme={true} sunType={SunTypes.Twilight} isLoaded={isLoaded} />
      <div className="learn-more">
        <div ref={contentRef} className="content-main">
          <FeedbackContent />
        </div>
      </div>
    </div>
  );
};

export default FeedbackPage;
