import { ChevronLeft } from 'lucide-react';
import React from 'react';

const BackBanner = ({ onClick }) => {
  return (
    <div className="back-banner">
      <button onClick={onClick} className="back-button">
        <ChevronLeft />
        <span>Back</span>
      </button>
    </div>
  );
};

export default BackBanner;
