// enhancedNotificationSystem.js
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import logger from '../utils/logger';

const STORAGE_KEY = 'solar-notifications';
const isDevelopment = process.env.NODE_ENV !== 'production';

// Notification UI styles for development mode
const notificationStyles = {
  container: `
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 16px;
    background: #1a1a1a;
    color: white;
    border-radius: 8px;
    max-width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: slideIn 0.3s ease-out;
  `,
  animations: `
    @keyframes slideIn {
      from { transform: translateX(100%); opacity: 0; }
      to { transform: translateX(0); opacity: 1; }
    }
    @keyframes fadeOut {
      from { opacity: 1; }
      to { opacity: 0; }
    }
  `,
};

const hasServiceWorker = () => {
  if (isDevelopment) {
    logger.log(
      'Service worker disabled in development mode - using fallback notification system',
    );
    return false;
  }
  return 'serviceWorker' in navigator && navigator.serviceWorker.controller;
};

async function requestNotificationPermission() {
  if (!('Notification' in window)) return false;
  const permission = await Notification.requestPermission();
  return permission === 'granted';
}

export const defaultNotificationSettings = {
  dawn: { enabled: false, timeOffset: '10' },
  sunrise: { enabled: false, timeOffset: '10' },
  uvaRise: { enabled: false, timeOffset: '10' },
  uvbRise: { enabled: false, timeOffset: '10' },
  uvbSet: { enabled: false, timeOffset: '10' },
  uvaSet: { enabled: false, timeOffset: '10' },
  sunset: { enabled: false, timeOffset: '10' },
  dusk: { enabled: false, timeOffset: '10' },
  nightfall: { enabled: false, timeOffset: '10' },
};

export const SolarNotificationEvents = Object.keys(defaultNotificationSettings);

// Enhanced showNotification function that works in both dev and prod
function showNotification(message, options = {}) {
  if (isDevelopment) {
    // Create custom UI notification in development
    if (!document.getElementById('notification-animations')) {
      const style = document.createElement('style');
      style.id = 'notification-animations';
      style.textContent = notificationStyles.animations;
      document.head.appendChild(style);
    }

    const notification = document.createElement('div');
    notification.style.cssText = notificationStyles.container;
    notification.innerHTML = `
      <div style="display: flex; align-items: start;">
        ${options.icon ? `<img src="${options.icon}" style="width: 20px; height: 20px; margin-right: 8px;" />` : ''}
        <div>
          <div style="font-weight: bold; margin-bottom: 4px;">Solar Event</div>
          <div>${message}</div>
        </div>
      </div>
    `;

    document.body.appendChild(notification);

    setTimeout(() => {
      notification.style.animation = 'fadeOut 0.3s ease-out';
      setTimeout(() => notification.remove(), 300);
    }, 5000);
  } else if (hasServiceWorker()) {
    // Use service worker in production
    navigator.serviceWorker.controller.postMessage({
      type: 'SCHEDULE_SINGLE_NOTIFICATION',
      message,
      icon: options.icon || '/path/to/default/icon.png',
      tag: options.tag || 'notification-' + Date.now(),
    });
  } else if ('Notification' in window && Notification?.permission === 'granted') {
    // Fallback to native notifications if no service worker
    new Notification(message, options);
  }
}

function saveNotificationSettings(settings) {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(settings));

  if (hasServiceWorker()) {
    navigator.serviceWorker.controller.postMessage({
      type: 'UPDATE_PREFERENCES',
      preferences: settings,
    });
  }
}

function loadNotificationSettings() {
  const stored = localStorage.getItem(STORAGE_KEY);
  return stored ? JSON.parse(stored) : defaultNotificationSettings;
}

function calculateNextOccurrence(eventTime, offset) {
  let targetTime = DateTime.fromJSDate(eventTime).minus({
    minutes: parseInt(offset, 10),
  });

  const now = DateTime.now();
  if (targetTime < now) {
    targetTime = targetTime.plus({ days: 1 });
  }

  return targetTime;
}

function calculateNotificationTimes(solarEvents, previousDayEvents = null) {
  const notifications = [];
  const settings = loadNotificationSettings();

  if (previousDayEvents?.nightfall && solarEvents?.sunrise && settings.sunrise.enabled) {
    notifications.push({
      time: DateTime.fromJSDate(previousDayEvents.nightfall),
      message: `Sunrise tomorrow at ${DateTime.fromJSDate(solarEvents.sunrise).toFormat('h:mm a')}`,
      eventName: 'sunrise',
      offset: 'previousDay',
      recurring: true,
    });
  }

  SolarNotificationEvents.forEach((eventName) => {
    const eventSettings = settings[eventName];
    if (!eventSettings.enabled) return;

    const eventTime = solarEvents[eventName];
    if (!eventTime) return;

    const offset = parseInt(eventSettings.timeOffset, 10);
    const notificationTime = calculateNextOccurrence(eventTime, offset);
    notifications.push({
      time: notificationTime,
      message: `${offset} minutes until ${eventName}`,
      eventName,
      offset,
      recurring: true,
    });
  });

  return notifications.sort((a, b) => a.time.toMillis() - b.time.toMillis());
}

// Store timeouts to clear them when needed
const activeTimeouts = new Set();

function clearExistingTimeouts() {
  activeTimeouts.forEach((timeout) => clearTimeout(timeout));
  activeTimeouts.clear();
}

// Add this debugging function
function isCloneable(obj, path = '') {
  try {
    const clone = structuredClone(obj);
    return true;
  } catch (error) {
    console.error(`Failed to clone at path: ${path}`, error);

    if (typeof obj === 'object' && obj !== null) {
      // Recursively check each property
      Object.entries(obj).forEach(([key, value]) => {
        const newPath = path ? `${path}.${key}` : key;
        if (typeof value === 'object' && value !== null) {
          isCloneable(value, newPath);
        }
      });
    }
    return false;
  }
}

function scheduleNotifications(todayEvents, tomorrowEvents, yesterdayEvents = null) {
  // Debug log the input events
  logger.log(
    'Debug - Input events:',
    safeStringify({
      today: todayEvents,
      tomorrow: tomorrowEvents,
      yesterday: yesterdayEvents,
    }),
  );

  const notifications = [
    ...calculateNotificationTimes(todayEvents, yesterdayEvents),
    ...calculateNotificationTimes(tomorrowEvents, todayEvents),
  ];

  // Debug log the calculated notifications
  logger.log('Debug - Calculated notifications:', safeStringify(notifications));

  // Test if notifications are cloneable
  logger.log('Testing if notifications are cloneable...');
  isCloneable(notifications, 'notifications');

  if (isDevelopment) {
    logger.log('Debug - Development mode, using timeouts');
    clearExistingTimeouts();
    notifications.forEach((notification) => {
      // Log each notification being scheduled
      logger.log('Debug - Scheduling notification:', safeStringify(notification));

      const timeUntilNotification = notification.time.toMillis() - Date.now();
      if (timeUntilNotification > 0) {
        const timeout = setTimeout(() => {
          showNotification(notification.message, {
            tag: notification.eventName,
            body: `Development notification for ${notification.eventName}`,
          });
        }, timeUntilNotification);
        activeTimeouts.add(timeout);
      }
    });
    return notifications;
  }

  if (!hasServiceWorker()) {
    logger.log('Debug - No service worker available');
    return notifications;
  }

  try {
    // Try to serialize each field individually first
    const serializedNotifications = notifications.map((notification) => {
      logger.log('Debug - Serializing notification:', safeStringify(notification));

      // Test each field individually
      const serializedFields = {
        time: (() => {
          try {
            return notification.time.toMillis();
          } catch (e) {
            console.error('Failed to serialize time:', e);
            return Date.now();
          }
        })(),
        message: (() => {
          try {
            return String(notification.message);
          } catch (e) {
            console.error('Failed to serialize message:', e);
            return '';
          }
        })(),
        eventName: (() => {
          try {
            return String(notification.eventName);
          } catch (e) {
            console.error('Failed to serialize eventName:', e);
            return '';
          }
        })(),
        offset: (() => {
          try {
            return notification.offset === 'previousDay'
              ? 'previousDay'
              : Number(notification.offset);
          } catch (e) {
            console.error('Failed to serialize offset:', e);
            return 0;
          }
        })(),
        recurring: (() => {
          try {
            return Boolean(notification.recurring);
          } catch (e) {
            console.error('Failed to serialize recurring:', e);
            return false;
          }
        })(),
      };

      logger.log('Debug - Serialized fields:', serializedFields);
      return serializedFields;
    });

    // Test if the serialized notifications are cloneable
    logger.log('Testing if serialized notifications are cloneable...');
    isCloneable(serializedNotifications, 'serializedNotifications');

    const message = {
      type: 'SCHEDULE_NOTIFICATIONS',
      notifications: serializedNotifications,
    };

    // Final test of the complete message
    logger.log('Testing if complete message is cloneable...');
    isCloneable(message, 'completeMessage');

    logger.log('Debug - Sending message to service worker:', safeStringify(message));
    navigator.serviceWorker.controller.postMessage(message);
  } catch (error) {
    console.error('Debug - Error in scheduleNotifications:', error);
    // Log the error stack
    console.error('Error stack:', error.stack);

    // Fallback to browser notifications
    logger.log('Debug - Falling back to browser notifications');
    notifications.forEach((notification) => {
      const timeUntilNotification = notification.time.toMillis() - Date.now();
      if (timeUntilNotification > 0) {
        setTimeout(() => {
          if (Notification?.permission === 'granted') {
            new Notification(notification.message, {
              tag: notification.eventName,
            });
          }
        }, timeUntilNotification);
      }
    });
  }

  return notifications;
}

// Helper function to safely stringify objects for debugging
function safeStringify(obj) {
  try {
    return JSON.stringify(
      obj,
      (key, value) => {
        if (value instanceof DateTime) {
          return `DateTime: ${value.toISO()}`;
        }
        return value;
      },
      2,
    );
  } catch (error) {
    return `Failed to stringify: ${error}`;
  }
}

function useNotificationSystem(todayEvents, tomorrowEvents, yesterdayEvents = null) {
  const [permission, setPermission] = useState(Notification?.permission);
  const [notificationSettings, setNotificationSettings] = useState(
    loadNotificationSettings(),
  );
  const [scheduledNotifications, setScheduledNotifications] = useState([]);

  useEffect(() => {
    const initializeNotifications = async () => {
      let permissionState;
      if ('Notification' in window) {
        permissionState = Notification?.permission;
        setPermission(permissionState);
      } else {
        const granted = await requestNotificationPermission();
        permissionState = granted ? 'granted' : 'denied';
        setPermission(permissionState);
      }

      if (
        !isDevelopment &&
        permissionState === 'granted' &&
        'serviceWorker' in navigator
      ) {
        logger.log('Initializing production service worker...');
        try {
          const registration = await navigator.serviceWorker.ready;
          logger.log('Service worker is ready:', registration);
        } catch (error) {
          console.error('Service worker initialization error:', error);
        }
      }
    };

    void initializeNotifications();

    return () => {
      if (isDevelopment) {
        clearExistingTimeouts();
      }
    };
  }, []);

  useEffect(() => {
    if (permission === 'granted' && todayEvents && tomorrowEvents) {
      const notifications = scheduleNotifications(
        todayEvents,
        tomorrowEvents,
        yesterdayEvents,
      );
      setScheduledNotifications(notifications);
    }
  }, [permission, todayEvents, tomorrowEvents, yesterdayEvents, notificationSettings]);

  const updateEventSettings = useCallback(
    (eventName, field, value) => {
      const newSettings = {
        ...notificationSettings,
        [eventName]: {
          ...notificationSettings[eventName],
          [field]: value,
        },
      };
      setNotificationSettings(newSettings);
      saveNotificationSettings(newSettings);
    },
    [notificationSettings],
  );

  // New method to trigger immediate notifications (useful for testing)
  const triggerImmediateNotification = useCallback((message, options = {}) => {
    showNotification(message, options);
  }, []);

  return {
    permission,
    notificationSettings,
    scheduledNotifications,
    updateEventSettings,
    requestPermission: requestNotificationPermission,
    triggerImmediateNotification,
  };
}

export default useNotificationSystem;
