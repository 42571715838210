import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronLeft, ChevronRight, ExternalLink } from 'lucide-react';
import '../css/Products.css';

export const ProductLink = ({ id, label, url, isTarget }) => {
  useEffect(() => {
    if (isTarget) {
      // Add a small delay to ensure the content is expanded first
      setTimeout(() => {
        document.getElementById(id)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 100);
    }
  }, [isTarget, id]);

  return (
    <div
      id={id}
      className={`product-item ${isTarget ? 'product-item--highlighted' : ''}`}
    >
      <span className="product-label">{label}</span>
      <div className="product-actions">
        <a href={url} target="_blank" rel="noopener noreferrer" className="product-link">
          <ExternalLink />
        </a>
      </div>
    </div>
  );
};

export const ProductCategory = ({
  id,
  label,
  description,
  items,
  isSubcategory = false,
  targetPath = [],
  currentPath = [],
}) => {
  const [expanded, setExpanded] = useState(false);
  const isInTargetPath =
    targetPath.length > 0 &&
    currentPath.length < targetPath.length &&
    targetPath[currentPath.length] === id;

  // Auto-expand if this category is in the path to the target
  useEffect(() => {
    if (isInTargetPath) {
      setExpanded(true);
      // Scroll to the category when it's expanded and in the target path
      document.getElementById(id)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isInTargetPath, id]);

  const handleExpand = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <div id={id}>
      <div
        onClick={handleExpand}
        className={`${isSubcategory ? 'product-subcategory' : 'product-category'}`}
      >
        <span className="product-label">{label}</span>
        <div className="product-actions">
          <button onClick={handleExpand} className="expand-button">
            {expanded ? <ChevronDown /> : <ChevronRight />}
          </button>
        </div>
      </div>

      {expanded && (
        <div className="product-content">
          {description && <div className="product-description">{description}</div>}
          <div className="product-sub-items">
            {items.map((item) =>
              item.items ? (
                <ProductCategory
                  key={item.id}
                  {...item}
                  isSubcategory={true}
                  targetPath={targetPath}
                  currentPath={[...currentPath, id]}
                />
              ) : (
                <ProductLink
                  key={item.id}
                  {...item}
                  isTarget={
                    targetPath.length > 0 && targetPath[targetPath.length - 1] === item.id
                  }
                />
              ),
            )}
          </div>
        </div>
      )}
    </div>
  );
};

// Helper function to find the path to a specific product
export const findProductPath = (items, targetId, currentPath = []) => {
  for (const item of items) {
    if (item.id === targetId) {
      return [...currentPath, item.id];
    }
    if (item.items) {
      const path = findProductPath(item.items, targetId, [...currentPath, item.id]);
      if (path) return path;
    }
  }
  return undefined;
};

export const ProductsSection = ({ productData, targetProduct }) => {
  const targetPath = targetProduct ? findProductPath(productData, targetProduct) : [];

  return (
    <div className="w-full max-w-2xl border rounded-lg">
      <div className="product-recommendations__header">
        <h1 className="text-2xl font-bold mb-4">Product Recommendations</h1>
      </div>
      {productData.map((category) => (
        <ProductCategory
          key={category.id}
          {...category}
          targetPath={targetPath}
          currentPath={[]}
        />
      ))}
    </div>
  );
};
