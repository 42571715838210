import React from 'react';

import { Pages, usePage } from '../providers/page-provider';
import { getSunTypeGradient } from '../domain/sun-type';
import { useInfoPanel } from './info-panel';

import '../css/Nav.css';
import { useTutorial } from '../providers/tutorial-provider';

const NavigationMenu = ({ menuItems, darkTheme, sunType }) => {
  const { goToPage, isActivePage } = usePage();
  const { hideInfoPanel } = useInfoPanel();
  const { nextStep } = useTutorial();

  const gradient = getSunTypeGradient(sunType);
  const colorClass = darkTheme ? 'dark-mode-text-color' : '';
  const navClass = darkTheme
    ? 'navigation-list dark-mode-shadow'
    : 'navigation-list light-mode-shadow';

  return (
    <nav className={`navigation-menu ${colorClass}`} style={{ background: gradient }}>
      <ul className={navClass}>
        {menuItems
          .sort((a, b) => a.index - b.index)
          .map((item, index) => {
            const isActiveItem = isActivePage(item.link);

            const onClick = () => {
              if (isActiveItem) {
                return;
              }
              if (typeof item.link === 'function') {
                item.link();
              } else {
                hideInfoPanel();
                goToPage(item.link);
                if (item.name === 'UV') {
                  nextStep();
                }
              }
            };

            return (
              <li
                id={item.id || undefined}
                key={item.key}
                className={`navigation-item ${index === 0 ? 'top-item' : ''}`}
              >
                <div
                  onClick={onClick}
                  className={`item-name ${isActiveItem ? 'active-item' : ''}`}
                >
                  {item.name}
                </div>
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

export default NavigationMenu;
