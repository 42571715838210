// src/services/mailerLiteApi.js

import axios from 'axios';
import { MailerLiteAPIKey } from '../config';

export const MailingListGroups = {
  SIL: {
    id: '134471427832153440',
    name: 'Sunlight Is Life',
    description: 'App users and interested subscribers',
  },
  SIL_BETA: {
    id: '144262973215475115',
    name: 'Sunlight Is Life - Beta',
    description: 'App Beta release users',
  },
  LEW_LOCAL: {
    id: '143996101588944552',
    name: 'Living Energy Wellness - Local',
    description: 'Local spa and wellness clients',
  },
  LEW: {
    id: '143996092849063874',
    name: 'Living Energy Wellness',
    description: 'General LEW customers and subscribers',
  },
  GENERAL: {
    id: '143996051090572972',
    name: 'General Content List',
    description: 'Newsletter and content subscribers',
  },
};

const BASE_URL = 'https://connect.mailerlite.com/api';

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: `Bearer ${MailerLiteAPIKey}`,
};

export const addSubscriber = async (email, referralSource, groups = []) => {
  console.log('Adding subscriber', email);
  const url = `${BASE_URL}/subscribers`;

  const data = {
    email,
    fields: {
      referral_source: referralSource,
    },
    groups: groups,
    status: 'active',
  };

  try {
    const response = await axios.post(url, data, { headers });
    return {
      success: response.status === 200 || response.status === 201,
      data: response.data.data,
      alreadySubscribed: response.status === 200,
    };
  } catch (error) {
    if (
      error.response?.status === 400 &&
      error.response?.data?.message?.includes('subscriber already exists')
    ) {
      // If subscriber exists, try to update their groups
      return await updateSubscriberGroups(email, groups);
    }
    throw error;
  }
};

export const updateSubscriberGroups = async (email, groupsToAdd = []) => {
  const subscriber = await getSubscriber(email);

  if (!subscriber) {
    throw new Error('Subscriber not found');
  }

  // Add subscriber to each specified group
  for (const groupId of groupsToAdd) {
    try {
      await axios.post(
        `${BASE_URL}/subscribers/${subscriber.id}/groups`,
        { group_id: groupId },
        { headers },
      );
    } catch (error) {
      console.error(`Error adding to group ${groupId}:`, error);
    }
  }

  return {
    success: true,
    data: subscriber,
    alreadySubscribed: true,
  };
};

// Get subscriber by ID or email
export const getSubscriber = async (identifier) => {
  try {
    let url;
    let params = {};

    // If identifier is a numeric ID
    if (!isNaN(identifier)) {
      url = `${BASE_URL}/subscribers/${identifier}`;
    } else {
      // If identifier is an email
      url = `${BASE_URL}/subscribers/search`;
      params = { query: identifier };
    }

    const response = await axios.get(url, { headers, params });

    // Handle different response structures based on endpoint
    const subscriber = !isNaN(identifier)
      ? response.data.data
      : response.data.data.find((sub) => sub.email === identifier);

    return subscriber || null;
  } catch (error) {
    if (error.response?.status === 404) {
      return null;
    }
    throw error;
  }
};

// Verify subscriber access
export const verifySubscriberAccess = async (identifier) => {
  try {
    const subscriber = await getSubscriber(identifier);

    if (!subscriber) {
      return {
        isSubscribed: false,
        hasAccess: false,
        subscriberData: null,
      };
    }

    // Get subscriber's groups
    const groupsResponse = await axios.get(
      `${BASE_URL}/subscribers/${subscriber.id}/groups`,
      { headers },
    );

    // Check if subscriber is in SIL group
    const hasAccess = groupsResponse.data.data.some(
      (group) => group.id === MailingListGroups.SIL.id,
    );

    return {
      isSubscribed: true,
      hasAccess,
      subscriberData: subscriber,
    };
  } catch (error) {
    console.error('Error verifying subscriber:', error);
    throw error;
  }
};

// Helper function to check app access
export const checkAppAccess = async (identifier) => {
  try {
    const { isSubscribed, hasAccess, subscriberData } =
      await verifySubscriberAccess(identifier);

    if (!isSubscribed) {
      throw new Error('Not subscribed');
    }

    if (!hasAccess) {
      throw new Error('No SIL access');
    }

    return {
      verified: true,
      subscriber: subscriberData,
    };
  } catch (error) {
    return {
      verified: false,
      error: error.message,
    };
  }
};
