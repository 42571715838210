import React, { createContext, useContext, useState, useEffect } from 'react';

const InstallPWAContext = createContext();

export const InstallPWAProvider = ({ children }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const beforeInstallPromptHandler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    };
  }, []);

  return (
    <InstallPWAContext.Provider value={{ deferredPrompt, setDeferredPrompt }}>
      {children}
    </InstallPWAContext.Provider>
  );
};

export const useInstallPWA = () => useContext(InstallPWAContext);
