import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/LearnMore.css';
import AppHeader from '../components/app_header';
import { SunTypes } from '../components/sun_path';
import { useBackground } from '../hooks/use-background-animation';
import { Pages, usePage } from '../providers/page-provider';
import BackBanner from '../components/back-banner';
import { LightTypes } from '../providers/solar-clock-provider';

const SunlightIsLife = () => (
  <>
    <h2>Welcome to Sunlight is Life</h2>
    <p>
      At Sunlight is Life, we explore the profound impact of sunlight on health and
      vitality. In a world increasingly distanced from nature, we aim to reconnect you
      with one of the most essential forces for well-being: the sun. Our mission is to
      highlight the benefits of sunlight in sychronizing you circadian rhythm, boosting
      mitochondrial function, enhancing melanin production, supporting vitamin D
      synthesis, and uplifting mood and energy levels. By understanding the science behind
      the sun's healing power, you can tap into nature's original source of vitality.
    </p>

    <p>
      Throughout history, sunlight has been revered in both ancient and modern cultures.
      From the Egyptians' worship of the sun god Ra to the Greeks' adoration of Apollo,
      civilizations across time recognized the sun's life-giving qualities. Sunlight was
      more than a symbol; it was a central part of daily life, influencing agricultural
      practices, religious rituals, and medicine.
    </p>

    <p>
      Similarly, indigenous cultures like those of Native Americans and Australian
      Aboriginal communities hold the sun in deep significance. Ceremonies often honor its
      life-giving power, recognizing its central role in nature and the well-being of
      their people. The sun's presence is woven into spiritual and practical aspects of
      life, showcasing its importance across time and geography.
    </p>

    <p>
      In the early 20th century, the practice of heliotherapy — the use of sunlight to
      treat illness — surged in popularity. Pioneered by figures like Dr. Auguste Rollier,
      a Swiss physician, heliotherapy was instrumental in treating conditions such as
      tuberculosis, rickets, and skin diseases. Rollier's clinics, which harnessed the
      power of the sun in the pristine Swiss Alps, were renowned for their success. During
      World War I, sunlight therapy was also used to aid the healing of wounds, improving
      recovery outcomes for injured soldiers.
    </p>

    <p>
      However, with the advent of antibiotics and the rise of pharmaceutical interventions
      in the mid-20th century, heliotherapy and other natural treatments faded from the
      medical spotlight. More recently, we’ve been taught to fear the sun, largely due to
      misguided concerns about skin cancer and premature aging. While protecting against
      overexposure is important, this widespread fear has led many to miss out on the
      sun's essential benefits, contributing to widespread vitamin D deficiencies and
      other health issues. At Sunlight is Life, we seek to shift this narrative and
      promote a balanced understanding of how the sun, when respected and used properly,
      can be a powerful tool in restoring your health and vitality naturally. Let us help
      you rediscover the sun’s potential to nurture your body, mind, and spirit.
    </p>
  </>
);

const SunlightScience = ({ targetSpectrum }) => {
  const isInfraredTarget =
    LightTypes.INFRARED === targetSpectrum ? 'spectrum-header--highlighted' : undefined;
  const isVisTarget =
    LightTypes.VISIBLE === targetSpectrum ? 'spectrum-header--highlighted' : undefined;
  const isUvaTarget =
    LightTypes.UVA === targetSpectrum ? 'spectrum-header--highlighted' : undefined;
  const isUvbTarget =
    LightTypes.UVB === targetSpectrum ? 'spectrum-header--highlighted' : undefined;

  console.log('isUvaTarget', isUvaTarget);
  return (
    <>
      <h2>The Science of Sunlight</h2>
      <p>
        Sunlight plays a vital role in regulating our biological systems and overall
        health. From enhancing mitochondrial function to supporting the production of
        essential hormones and neurotransmitters, the interaction between sunlight and our
        bodies is both complex and profound. The science behind these processes includes
        various types of light that contribute to different aspects of our health and
        vitality.
      </p>

      <h3 id={`${LightTypes.INFRARED}-target`} className={isInfraredTarget}>
        Infrared Light and Cellular Energy Production
      </h3>
      <p>
        Infrared light, particularly during sunrise and sunset, penetrates deeply into the
        tissues and interacts with our mitochondria—the energy centers of the cell. It
        enhances the efficiency of the electron transport chain, a process crucial for ATP
        production, the cell's main energy currency, and lowers blood sugar in the
        process. Infrared light also structures water within cells, creating charge
        separation that functions like a battery, storing energy for various cellular
        processes. This structured water enhances metabolic function, improving energy
        levels and cellular repair.
      </p>

      <h3 id={`${LightTypes.VISIBLE}-target`} className={isVisTarget}>
        Blue Light and the Body's Master Clock
      </h3>
      <p>
        Blue light, abundant during daylight hours, strongly influences the body's
        circadian rhythm by stimulating specialized cells in the retina that are sensitive
        to light. These cells signal the brain’s master clock, the suprachiasmatic nucleus
        (SCN), to regulate the timing of sleep-wake cycles, hormone release, and other
        physiolgoical rhythms. This helps synchronize the body’s internal clock with the
        natural light-dark cycle, affecting everything from mood to metabolism.
      </p>

      <h3 id={`${LightTypes.UVA}-target`} className={isUvaTarget}>
        UVA Light and Neurotransmitter Production
      </h3>
      <p>
        Ultraviolet A (UVA) light, part of the sunlight spectrum that appears later in the
        day and fades earlier, plays a key role in boosting our vitality. Exposure to UVA
        light through the eyes energizes specific amino acids like tyrosine, tryptophan,
        and phenylalanine, triggering the production of neurotransmitters such as
        dopamine, serotonin, and melatonin. Serotonin, often referred to as the
        "feel-good" neurotransmitter, and melatonin, the "sleep hormone," are crucial for
        regulating mood and sleep patterns. Dopamine, essential for motivation and reward,
        helps drive focus and positive feelings. Additionally, UVA light helps balance
        hormone production influenced by blue light through processes like sulfation and
        photolysis, ensuring that unregulated hormone production doesn’t lead to long term
        health issues.
      </p>
      <p>
        When we near our limit of healthy UV exposure, nitric oxide production causes
        blood vessels to dilate, improving circulation. Additionally, the production of
        histidine leads to a slight reddening of the skin, signaling that it’s time to
        seek shade and prevent overexposure. UVA light also helps bring melanin to the
        surface through melanocytes, enhancing natural protection for future UV exposure.
      </p>

      <h3 id={`${LightTypes.UVB}-target`} className={isUvbTarget}>
        UVB Light, Vitamin D, and Peptide Activation
      </h3>
      <p>
        Ultraviolet B (UVB) light is essential for synthesizing vitamin D in the skin, a
        crucial nutrient for immune function, bone health, and is associated with overall
        lower occurance of disease, including cancer. UVB stimulates the production of
        melanin, the pigment that darkens our skin and protects it from sun damage.
        Melanin also plays a role in "human photosynthesis." Recent research by Arturo
        Solis Herrara highlights how UV light energizes melanin, enabling it to split
        water molecules into hydrogen and oxygen within cells. This process supports
        mitochondrial energy production, making melanin a key player in boosting cellular
        energy and overall health.
      </p>
      <p>
        When UVB light penetrates the skin, it triggers the cleavage of a precursor
        protein called pro-opiomelanocortin (POMC) into biologically active peptides.
        These include beta-endorphin, which provides pain relief and enhances mood, and
        alpha-melanocyte-stimulating hormone (α-MSH), which stimulates melanin production,
        aiding in skin pigmentation and protection from DNA damage.
      </p>
    </>
  );
};

const ScientificFoundations = () => (
  <>
    <h2>Scientific Foundations</h2>
    <p>
      While the molecular biology of circadian rhythms has long been studied, recent
      research has expanded into the realm of quantum biology, an emerging field that
      explores the role of quantum phenomena—interactions involving subatomic particles
      like electrons—in biological systems. Quantum biology brings us closer to
      understanding how light interacts with living organisms, revealing fundamental
      processes like the photoelectric effect, discovered by Albert Einstein, which
      explains how different spectra of sunlight interact with electrons in our bodies,
      triggering vital physiological reactions. This quantum understanding of light’s
      interaction with biology is pivotal in regulating circadian rhythms, and its
      implications are profound.
    </p>

    <p>
      The importance of circadian science was recognized in 2017 when the Nobel Prize was
      awarded for breakthroughs in our understanding of the biological clock, highlighting
      how sunlight regulates the body’s natural cycles. Quantum biological mechanisms add
      a new dimension to this, showing that light’s influence on life is far more
      intricate than previously thought. These discoveries are more than just scientific
      revelations; they represent a paradigm shift in how we connect with the natural
      world.
    </p>

    <p>
      Ancient cultures revered the sun as a life-giving force, and modern science is now
      revealing the deep wisdom behind those practices. From regulating sleep and mood to
      driving complex biochemical reactions, light is at the core of our vitality. As we
      rediscover the importance of the sun, we are bridging ancient understanding with
      cutting-edge science, reaffirming the importance of living in harmony with nature.
    </p>
  </>
);

const LastPageIndex = 2;

const CircadianScience = ({ isLoaded }) => {
  const contentRef = useRef(null);
  useBackground(SunTypes.Twilight);
  const { getPageParams, goToPage } = usePage();
  const { targetPage, targetSpectrum } = getPageParams();

  useEffect(() => {
    if (targetPage) {
      setPageIndex(targetPage);

      if (targetSpectrum) {
        const targetElement = `${targetSpectrum}-target`;
        console.log('scrolling to element', targetElement);

        // Add a small delay to ensure the content is expanded first
        setTimeout(() => {
          document.getElementById(targetElement)?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 100);
      }
    }
  }, [targetPage, targetSpectrum]);

  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [pageIndex]);

  const handlePreviousPage = () => {
    if (pageIndex > 0) {
      setPageIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextPage = () => {
    if (pageIndex < LastPageIndex) {
      setPageIndex((prevIndex) => prevIndex + 1);
    }
  };

  console.log(
    'targetSpectrum',
    targetSpectrum,
    'isUVA?',
    LightTypes.UVA === targetSpectrum,
  );
  function getPageContent() {
    if (pageIndex === 0) {
      return <SunlightIsLife />;
    }
    if (pageIndex === 1) {
      return <SunlightScience targetSpectrum={targetSpectrum} />;
    }
    if (pageIndex === 0) {
      return <ScientificFoundations />;
    }
  }

  return (
    <div className="App">
      {targetPage && targetSpectrum && (
        <BackBanner onClick={() => goToPage(Pages.Clock)} />
      )}
      <AppHeader darkTheme={true} sunType={SunTypes.Twilight} isLoaded={isLoaded} />
      <div className="learn-more">
        <div ref={contentRef} className="content-main">
          {getPageContent()}
        </div>
        <div className="navigation-buttons">
          <button onClick={handlePreviousPage} disabled={pageIndex === 0}>
            Previous
          </button>
          <button onClick={handleNextPage} disabled={pageIndex === LastPageIndex}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default CircadianScience;
