import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from '../providers/auth-provider';
import { isStandalone } from '../utils/browser-utils';

export const FeatureFlags = {
  AnimateSolarClock: 'animateSolarClock',
  AllowLocationSearch: 'allowLocationSearch',
  IsDateSelectActive: 'isDateSelectActive',
  CompassPageIsActive: 'compassPageIsActive',
  DebugIsActive: 'debugIsActive',
  IsManualCompassEnabled: 'isManualCompassEnabled',
  IsTutorialEnabled: 'isTutorialEnabled',
  IsNotificationsEnabled: 'IsNotificationsEnabled',
  DebugLogIsEnabled: 'DebugLogIsEnabled',
};

const devFeatureFlags = {
  [FeatureFlags.AnimateSolarClock]: true,
  [FeatureFlags.AllowLocationSearch]: true,
  [FeatureFlags.IsDateSelectActive]: true,
  [FeatureFlags.CompassPageIsActive]: true,
  [FeatureFlags.DebugIsActive]: false,
  [FeatureFlags.IsManualCompassEnabled]: true,
  [FeatureFlags.IsTutorialEnabled]: true,
  [FeatureFlags.IsNotificationsEnabled]: false,
  [FeatureFlags.DebugLogIsEnabled]: true,
};

const prodFeatureFlags = {
  [FeatureFlags.AnimateSolarClock]: true,
  [FeatureFlags.AllowLocationSearch]: true,
  [FeatureFlags.IsDateSelectActive]: false,
  [FeatureFlags.CompassPageIsActive]: true,
  [FeatureFlags.DebugIsActive]: false,
  [FeatureFlags.IsTutorialEnabled]: true,
  [FeatureFlags.IsNotificationsEnabled]: false,
  [FeatureFlags.DebugLogIsEnabled]: false,
};

const userFeaturesMap = {
  [3977633761]: {
    [FeatureFlags.AnimateSolarClock]: false,
    [FeatureFlags.AllowLocationSearch]: true,
    [FeatureFlags.IsDateSelectActive]: true,
    [FeatureFlags.CompassPageIsActive]: true,
    [FeatureFlags.DebugIsActive]: true,
    [FeatureFlags.IsManualCompassEnabled]: false,
    [FeatureFlags.IsTutorialEnabled]: true,
    [FeatureFlags.IsNotificationsEnabled]: true,
    [FeatureFlags.DebugLogIsEnabled]: true,
  },
};

const ENV = process.env.NODE_ENV;

export function getFeature(featureName, user) {
  console.log('userID', user?.id);
  const userFeatureFlags = userFeaturesMap[user?.id];
  if (userFeatureFlags) {
    return userFeatureFlags[featureName];
  }
  if (ENV === 'development') {
    return devFeatureFlags[featureName];
  }
  return prodFeatureFlags[featureName];
}

export default function useFeature() {
  const { user } = useAuth();

  const isFeatureActive = useCallback(
    (featureName) => {
      return getFeature(featureName, user);
    },
    [user.id],
  );

  return isFeatureActive;
}
