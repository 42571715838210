import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Pages, usePage } from './page-provider';
import { ModalTypes, useModal } from './modal-provider';
import { useSolarClock } from './solar-clock-provider';
import { CircadianEventType } from '../components/circadian_event';
import { useInfoPanel } from '../components/info-panel';
import { Days, formatDayTimeAndZone } from '../utils/time';
import {
  loadTutorialDataFromCache,
  saveIntroDataToCache,
  saveTutorialDataToCache,
} from '../utils/local-storage';
import useFeature, { FeatureFlags } from '../hooks/use-feature';

const isTutorialSeen = async () => {
  const introData = await loadTutorialDataFromCache();
  return introData?.seen;
};

const markTutorialAsSeen = () => {
  saveTutorialDataToCache({ seen: true });
};

const resetTutorial = () => {
  saveTutorialDataToCache({ seen: false });
};

const TutorialContext = createContext();

export const useTutorial = () => {
  const context = useContext(TutorialContext);
  if (!context) {
    throw new Error('useTutorial must be used within a TutorialProvider');
  }
  return context;
};

export const TutorialProvider = ({ children }) => {
  const { goToPage } = usePage();
  const { openModal, closeModal } = useModal();
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const { activateEvent, timezone } = useSolarClock();
  const { showInfoPanelPreview, infoPanel, hideInfoPanel } = useInfoPanel();
  const tomorrow = useMemo(() => Days.Tomorrow(timezone), [timezone]);
  const isFeatureActive = useFeature();

  const steps = useMemo(
    () => [
      {
        target: '#app-header-anchor',
        content:
          'Welcome! Would you like a quick tour of Sunlight is Life? Feel free to follow along or explore on your own - you can exit anytime and come back to it later in the settings page.',
        placement: 'bottom',
        disableBeacon: true,
      },
      {
        target: '#schedule-button-anchor',
        content:
          "Great! Click 'Next' to make your way through the tutorial. Let's get started by going to the Schedule page to see all of today's sun events.",
        placement: 'bottom',
        disableBeacon: true,
        metadata: {
          action: () => goToPage(Pages.Clock),
        },
      },
      {
        target: '#location-display-anchor',
        content:
          "We've automatically detected your location to show you the most accurate sun times. Your location stays the same until you choose to change it. Just click here whenever you want to update it.",
        placement: 'bottom-start',
        waitForTarget: true,
        metadata: {
          action: () => openModal(ModalTypes.LocationSearch),
        },
      },
      {
        target: '#location-search-anchor',
        content:
          'Here you can search for a new location, or let us detect where you are now. This helps us give you the most accurate information for your exact spot.',
        placement: 'right',
        disableOverlay: false,
        waitForTarget: true,
        metadata: {
          action: () => closeModal(),
        },
      },
      {
        target: '#events-item-anchor-solarnoon',
        content:
          "Here's your personalized schedule of today's sun events! You can click on any event to learn more about what's happening at that specific time.",
        placement: 'left',
        waitForTarget: true,
        metadata: {
          action: () => {
            activateEvent(CircadianEventType.SolarNoon);
            showInfoPanelPreview(CircadianEventType.SolarNoon);
          },
        },
      },
      {
        target: '#app-header-anchor',
        content:
          "For each sun event, we'll show you what's happening, how best to observe it, and why it matters as well as some recommended products. When you're ready to move on you can close the panel by clicking the 'x' in it's upper right corner.",
        placement: 'true',
        waitForTarget: true,
        metadata: {
          action: () => hideInfoPanel(),
        },
      },
      {
        target: '#header-time-event-anchor',
        content:
          'Notice how the time in the header has updated to match the event you selected? Every feature in the app now syncs to this time.',
        placement: 'bottom',
        waitForTarget: true,
        metadata: {
          action: () => activateEvent(),
        },
      },
      {
        target: '#today-anchor',
        content:
          "If you want to return to the current time, just click 'Today' - it'll stay in sync with your location.",
        placement: 'bottom',
        waitForTarget: true,
      },
      {
        target: '#tomorrow-anchor',
        content:
          "Clicking 'Tomorrow' takes you straight to tomorrow's sunrise time, for easy viewing.",
        placement: 'bottom',
        waitForTarget: true,
        // metadata: {
        //   action: () => activateEvent(CircadianEventType.Sunrise, tomorrow),
        // },
      },
      {
        target: '#uv-feature-anchor',
        content:
          "Let's check out the UV feature - here you'll learn about sun safety for your location and time.",
        placement: 'left',
        metadata: {
          action: () => goToPage(Pages.Uv),
        },
      },
      {
        target: '#uv-status-anchor',
        content:
          "This page shows the sun exposure risk based on the current forecast, but everyone's skin responds differently to the sun!",
        placement: 'bottom',
      },
      {
        target: '#skin-type-selector-anchor',
        content:
          'Select your skin type here so we can provide personalized sun safety guidance tailored just for you.',
        placement: 'bottom',
      },
      {
        target: '#adjustment-factors-anchor',
        content:
          'Fine-tune your sun safety profile by adjusting these factors based on your activities and environment.',
        placement: 'bottom',
      },
      {
        target: '#safe-time-anchor',
        content:
          "Click 'Safe Exposure Time' to explore our science-based recommendations and learn how we personalize sun safety just for you.",
        placement: 'bottom',
      },
      {
        target: '#compass-feature-anchor',
        content:
          "Now let's try the Compass feature! This will help you locate the sun and moon in your sky.",
        placement: 'top',
        metadata: {
          action: () => goToPage(Pages.Compass),
        },
      },
      {
        target: '#compass-page-anchor',
        content:
          "Your device should ask permission to use its compass. Once approved, rotate your phone until the red arrow aligns with North - this will show you exactly where to find the sun and moon. Don't worry if you're on a tablet or computer without a compass; you'll see a helpful fixed image instead.",
        placement: 'bottom',
        waitForTarget: true,
      },
      {
        target: '#troubleshoot-compass-anchor',
        content:
          "Need help with the compass? No worries! You can click 'Troubleshoot' for step-by-step guidance on getting everything working smoothly. We'll have you oriented in no time.",
        placement: 'right',
      },
      {
        target: '#time-selector-anchor',
        content:
          "Let's explore different days! You can click the time in the upper right corner to open a calendar where you can select any day you're interested in.",
        placement: 'bottom-start',
      },
      {
        target: '#event-selector-anchor',
        content:
          "Want to see a different time of day? You can click this label to choose from all the day's sun events. You can do this from any page in the app.",
        placement: 'left',
      },
      {
        target: '#feedback-menu-anchor',
        content:
          "There's even more to explore! Open our menu to discover additional features and ways to make Sunlight is Life work better for you.",
        placement: 'bottom',
        waitForTarget: true,
      },
      {
        target: '#app-header-anchor',
        content:
          "We're always improving! Have ideas or feedback? Click Feedback/Feature Request and let me know what you think. Now, let's head back to the Schedule page and start exploring the sun times in your area!",
        placement: 'bottom',
        metadata: {
          action: () => {
            activateEvent();
            goToPage(Pages.Clock);
          },
        },
      },
    ],
    [tomorrow],
  );

  const dismissTutorial = () => {
    markTutorialAsSeen();
    setRunTour(false);
    setStepIndex(0);
    setShowTutorial(false);
  };

  const handleJoyrideCallback = useCallback((data) => {
    const { action, index, status, type, step } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || ACTIONS.CLOSE === action) {
      dismissTutorial();
    } else if (type === EVENTS.STEP_BEFORE) {
      // Scroll to the target element if it exists and is not visible
      const targetElement = document.querySelector(step.target);
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        const isVisible =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth);

        if (!isVisible) {
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    } else if (type === EVENTS.STEP_AFTER) {
      // Handle next navigation
      if (action === ACTIONS.NEXT && steps[index]?.metadata?.action) {
        steps[index].metadata.action();
      }
      setStepIndex(index + 1);
    }
  }, []);

  const [showTutorial, setShowTutorial] = useState(true);

  useEffect(() => {
    isTutorialSeen().then((isSeen) => {
      if (isSeen) {
        setShowTutorial(false);
      } else {
        setShowTutorial(true);
      }
    });
  }, []);

  function initTutorial() {
    setRunTour(true);
    setStepIndex(0);
  }

  const startTutorial = useCallback(() => {
    if (!isFeatureActive(FeatureFlags.IsTutorialEnabled)) {
      return;
    }
    isTutorialSeen().then((isSeen) => {
      if (!isSeen) {
        if (!showTutorial) {
          return;
        }

        console.log('Starting Tutorial');
        initTutorial();
      }
    });
  }, [showTutorial]);

  const activateTutorial = () => {
    resetTutorial();
    setShowTutorial(true);
  };

  const tutorialValue = {
    nextStep: () => setStepIndex((prev) => prev + 1),
    goToStep: (step) => setStepIndex(step),
    startTutorial,
    stepIndex,
    activateTutorial,
    dismissTutorial,
    isTutorialActive: showTutorial,
  };

  return (
    <TutorialContext.Provider value={tutorialValue}>
      <Joyride
        steps={steps}
        run={runTour}
        stepIndex={stepIndex}
        continuous
        showProgress
        showSkipButton
        hideBackButton={true}
        spotlightClicks={false}
        disableOverlayClose={true}
        styles={{
          options: {
            primaryColor: '#4338ca',
            zIndex: 1000,
          },
          tooltip: {
            borderRadius: '8px',
            padding: '16px',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)', // Adjust the last value (0.3) to make it lighter
          },
        }}
        floaterProps={{
          options: {
            preventOverflow: {
              boundariesElement: 'viewport',
              padding: 5, // Space between popover and viewport edge,
              zIndex: 10000, // Set this higher than your other overlays
            },
            overlay: {
              zIndex: 9999,
            },
            tooltip: {
              zIndex: 10001, // Make sure tooltip is above overlay
            },
          },
        }}
        callback={handleJoyrideCallback}
        disableScrollParentFix
      />
      {children}
    </TutorialContext.Provider>
  );
};

export default TutorialProvider;
