import React, { useState } from 'react';
import { ModalTypes, useModal } from '../../providers/modal-provider';
import Modal from './modal';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const PaginatedExposureGuide = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const pages = [
    {
      title: 'Introduction',
      content: (
        <div className="space-y-4">
          <h1 className="text-2xl font-bold mb-6">Safe Sun Exposure Guide</h1>
          <p>
            What does safe sun exposure really mean? Many of us think it's as simple as
            applying sunscreen and staying out as long as we want. But there's actually a
            smarter, more natural way to protect our skin while getting the benefits we
            need.
          </p>
          <p>
            While sunscreen can prevent burning, it also masks important signals our body
            uses to tell us when we've had enough sun. When we block these signals with
            sunscreen, we often stay in the sun far longer than our skin can actually
            handle, leading to hidden damage that shows up later as premature aging and
            wrinkles.
          </p>
          <p>
            Our bodies are designed to protect themselves through careful sun exposure.
            Regular, mindful time in the sun actually helps our skin build its own natural
            defenses, including vitamin D production, which research shows helps protect
            against various forms of cancer--including skin cancer!
          </p>
        </div>
      ),
    },
    {
      title: 'Understanding MED',
      content: (
        <div className="space-y-4">
          <h2 className="text-xl font-bold mb-4">Understanding Safe Sun Exposure</h2>
          <p>
            Safe sun exposure time is determined by factors such as your skin type, UV
            index, recent sun exposure, altitude, latitude, and time of year. We take a
            personalized approach to sun exposure, using what is known as the Minimal
            Erythemal Dose (MED) as the limiting factor.
          </p>
          <p>
            MED is the amount of UV radiation your skin can handle before it shows visible
            signs of redness. Not only is this reddening harmless, it is actually
            beneficial, so don't be afraid of getting a little red. However, it's
            important to note that this redness, which results from increased blood flow,
            does not appear immediately upon reaching the MED. Typically, it starts to
            develop <strong>1 to 3 hours</strong> after exposure ends, as your skin reacts
            to the dose of UV radiation it received.
          </p>
        </div>
      ),
    },
    {
      title: 'Warning Signs',
      content: (
        <div className="space-y-4">
          <h2 className="text-xl font-bold mb-4">
            Recognizing Early Warning Signs of Reaching Your MED
          </h2>
          <p>
            Although the visible redness of reaching your MED may not appear immediately,
            there are several important warning signs. Paying attention to these signs can
            help you avoid overexposure:
          </p>
          <div className="space-y-3 mt-4">
            <div className="p-3 bg-gray-50 rounded">
              <strong>Warmth or Heat on the Skin:</strong> Your skin may start to feel
              noticeably warmer, even though it isn't red or painful yet. This warmth is a
              result of increased blood flow.
            </div>
            <div className="p-3 bg-gray-50 rounded">
              <strong>Mild Tingling or Sensitivity:</strong> Some people experience a
              slight tingling sensation or increased sensitivity in the skin.
            </div>
            <div className="p-3 bg-gray-50 rounded">
              <strong>Tightness or Dryness:</strong> If your skin starts feeling tight or
              dry, it might indicate dehydration from sun exposure.
            </div>
            <div className="p-3 bg-gray-50 rounded">
              <strong>Slight Darkening or Freckling:</strong> You may notice your skin
              becoming slightly darker or freckles appearing more pronounced.
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'More Warning Signs',
      content: (
        <div className="space-y-4">
          <h2 className="text-xl font-bold mb-4">Additional Warning Signs</h2>
          <div className="space-y-3">
            <div className="p-3 bg-gray-50 rounded">
              <strong>Skin Temperature Disparity:</strong> Compare a sun-exposed area of
              your skin to an unexposed area. If the exposed area feels significantly
              warmer, it's a sign that you are nearing your MED.
            </div>
            <div className="p-3 bg-gray-50 rounded">
              <strong>Subtle Flush or Pinkish Tone:</strong> If you have fair skin, you
              may see a very subtle flush developing.
            </div>
            <div className="p-3 bg-gray-50 rounded">
              <strong>Increased Sensitivity or Mild Discomfort:</strong> Your skin may
              become more sensitive to touch or feel slightly uncomfortable.
            </div>
          </div>
          <p className="mt-4">
            Everyone's skin reacts differently to UV radiation, and these calculations
            serve only as guidelines. It is crucial to monitor your body's response to the
            sun when spending extended time in direct sunlight.
          </p>
          <p>
            The face and neck are three times more sensitive to UV than the rest of your
            body, so wear a hat or take frequent shade breaks to protect yourself.
          </p>
        </div>
      ),
    },
    {
      title: 'MED and Erythema',
      content: (
        <div className="space-y-4">
          <h2 className="text-xl font-bold mb-4">Understanding MED and Erythema</h2>
          <p>
            Your skin's sensitivity to UV radiation is quantified by the{' '}
            <strong>Minimal Erythemal Dose (MED)</strong>; the maximum amount of sun you
            can handle before visible signs of redness, or <strong>erythema</strong>, sets
            in. Not all redness indicates skin damage though.
          </p>
          <p>
            <strong>Level 0 erythema</strong> is a result of increased blood flow to the
            skin due to the release of <strong>nitric oxide</strong>, which occurs in
            response to UV exposure, particularly UV-A. This initial redness is a sign of
            vasodilation, not sunburn, and it typically fades quickly after sun exposure.
          </p>
          <p>
            Vasodilation is beneficial because it reduces your blood pressure and is
            associated with a lower incidence of heart attack and stroke. While nitric
            oxide-induced vasodilation is beneficial, continued exposure beyond this point
            can lead to more intense levels of erythema, including sunburn, where skin
            damage occurs.
          </p>
        </div>
      ),
    },
    {
      title: 'Calculations',
      content: (
        <div className="space-y-4">
          <h2 className="text-xl font-bold mb-4">
            How We Calculate Your Safe Sun Exposure Time
          </h2>
          <p>We calculate the safe exposure time using the following factors:</p>
          <div className="space-y-3 mt-4">
            <div className="p-3 bg-gray-50 rounded">
              <strong>UV Index:</strong> A measure of the sun's UV radiation. The higher
              the UV index, the stronger the radiation.
            </div>
            <div className="p-3 bg-gray-50 rounded">
              <strong>Altitude:</strong> Higher altitudes increase UV exposure, roughly
              10% for every 1,000 meters.
            </div>
            <div className="p-3 bg-gray-50 rounded">
              <strong>Latitude:</strong> The closer you are to the equator, the stronger
              the UV radiation.
            </div>
            <div className="p-3 bg-gray-50 rounded">
              <strong>Time of Year:</strong> UV radiation is stronger during summer
              months.
            </div>
            <div className="p-3 bg-gray-50 rounded">
              <strong>Adjustment Factors:</strong> We also consider personal sun exposure
              factors, such as your caution level, tan level, and tan retention.
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Limitations',
      content: (
        <div className="space-y-4">
          <h2 className="text-xl font-bold mb-4">Guideline Limitations</h2>
          <p>
            These exposure time estimates provide a starting point for safe sun exposure.
            However, every individual's skin responds differently to UV radiation, and
            factors like hydration, medications, diet, or pre-existing health conditions
            may also influence your sensitivity to the sun.
          </p>
          <p>
            Always prioritize your body's unique reactions, adjust your time in the sun
            accordingly, and remember—this is for educational purposes only. This is not
            medical advice.
          </p>
        </div>
      ),
    },
  ];

  const goToNextPage = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6">
      <div className="min-h-[600px]">{pages[currentPage].content}</div>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 0}
          className="gradient-button button-small button-shadow"
        >
          Previous
        </button>

        <div style={{ margin: '8px' }}>
          <strong>
            Page {currentPage + 1} of {pages.length}
          </strong>
        </div>

        <button
          onClick={goToNextPage}
          disabled={currentPage === pages.length - 1}
          className="gradient-button button-small button-shadow"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default function ExposureGuideModal({ darkTheme, sunType }) {
  const { isModalOpen, closeModal } = useModal();

  const modalResultsClass = darkTheme ? 'modal-results modal-dark' : 'modal-results';

  return (
    <Modal
      show={isModalOpen(ModalTypes.ExposureGuide)}
      darkTheme={darkTheme}
      sunType={sunType}
      onClose={closeModal}
    >
      <PaginatedExposureGuide />
    </Modal>
  );
}
