import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import { SolarClockProvider } from './providers/solar-clock-provider';
import { ModalProvider } from './providers/modal-provider';
import { InfoPanelProvider } from './components/info-panel';
import AuthProvider from './providers/auth-provider';
import { UvProvider } from './providers/uv-provider';
import { PageProvider } from './providers/page-provider';
import ErrorBoundary from './components/error-boundary';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import InstallPWA, { InstallPWABanner } from './components/install-pwa';
import { VersionProvider } from './providers/version-provider';
import TutorialProvider from './providers/tutorial-provider';
import { InstallPWAProvider } from './providers/install-pwa-provider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <VersionProvider>
      <AuthProvider>
        <SolarClockProvider>
          <InstallPWAProvider>
            <PageProvider>
              <InfoPanelProvider>
                <ModalProvider>
                  <TutorialProvider>
                    <UvProvider>
                      <App />
                      <InstallPWABanner />
                    </UvProvider>
                  </TutorialProvider>
                </ModalProvider>
              </InfoPanelProvider>
            </PageProvider>
          </InstallPWAProvider>
        </SolarClockProvider>
      </AuthProvider>
    </VersionProvider>
  </ErrorBoundary>,
);

serviceWorkerRegistration.register();
