import React, { useCallback } from 'react';
import { useSolarClock } from '../providers/solar-clock-provider';
import { ModalTypes, useModal } from '../providers/modal-provider';
import { CircadianEventType } from './circadian_event';
import '../css/Nav.css';
import { useInfoPanel } from './info-panel';
import { useUvContext } from '../providers/uv-provider';
import { useAuth } from '../providers/auth-provider';
import useFeature, { FeatureFlags } from '../hooks/use-feature';
import { useTutorial } from '../providers/tutorial-provider';

const UvNavigation = ({ darkTheme, isUvLoading }) => {
  const { openModal } = useModal();
  const { user } = useAuth();
  const isFeatureActive = useFeature();
  const { nextStep } = useTutorial();

  const openExposureGuideModal = useCallback(
    () => openModal(ModalTypes.ExposureGuide),
    [],
  );
  const openSkinTypeModal = useCallback(() => openModal(ModalTypes.SkinType), []);
  const openAdjustmentFactorsModal = useCallback(() => {
    nextStep();
    openModal(ModalTypes.AdjustmentFactors);
  }, []);
  const openLocationModal = useCallback(() => {
    if (isFeatureActive(FeatureFlags.AllowLocationSearch)) {
      openModal(ModalTypes.LocationSearch);
    }
  }, []);
  const { skinType } = useUvContext();
  const { location, isL } = useSolarClock();

  if (isUvLoading) {
    return (
      <div className="nav-wrapper-loading">
        <div className="nav-item-loading">&nbsp;</div>
      </div>
    );
  }
  const styleSuffix = darkTheme ? '-dark' : '-light';
  const navWrapperClass = `nav-wrapper${styleSuffix}`;
  const navItemClass = `nav-item${styleSuffix}`;
  const navActionItemClass = `nav-item${styleSuffix} underlined nav-item-small`;
  const displayLocation = isUvLoading ? '--, --' : location;

  const onClickExposureGuide = () => {
    openExposureGuideModal();
  };

  const onClickSkinTypeGuide = () => {
    openSkinTypeModal();
  };

  const onClickAdjustmentFactors = () => {
    openExposureGuideModal();
  };

  return (
    <div className={navWrapperClass}>
      <>
        <div className={navItemClass} onClick={openLocationModal}>
          {displayLocation}
        </div>
        <div
          id="skin-type-selector-anchor"
          className={navActionItemClass}
          onClick={onClickSkinTypeGuide}
        >
          {skinType ? `Skin Type ${skinType}` : 'Select Your Skin Type'}
        </div>
        <div
          id="adjustment-factors-anchor"
          className={navActionItemClass}
          onClick={openAdjustmentFactorsModal}
        >
          Adjustment Factors
        </div>
        {/*<div className={navActionItemClass} onClick={onClickExposureGuide}>*/}
        {/*  Exposure Guide*/}
        {/*</div>*/}
      </>
    </div>
  );
};

export default UvNavigation;
